.patent_modal_overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10000;
  .patent_modal_content img {
    max-width: 90vw;
    max-height: 90vh;
    border-radius: 4px;
  }
}
@include mobile{
  .patent_modal_overlay {
    .patent_modal_content img {
      max-width: 100vw;
      max-height: 100vh;
      border-radius: 4px;
    }
  }
}