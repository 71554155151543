.skeleton_news_box {
  margin-top: 1.25rem;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(18.75rem, 1fr)); 
  gap: 0.625rem;

  .skeleton_news_item {
    display: flex;
    flex-direction: column;
    border: 0.125rem solid #383a3a; 
    border-radius: 1.375rem; 
    overflow: hidden;
    background-color: #222222;
    color: #ffffff;
    text-decoration: none;

    .skeleton_news_content {
      display: flex;
      flex-direction: column;
      padding: 1.52rem 1.5rem;

      .skeleton_news_date {
        display: flex;
        justify-content: space-between;
        margin-bottom: 0.96rem;

        div {
          display: flex;
          align-items: flex-end;
          gap: 0.012rem;

          span {
            font-size: 0.75em;
            font-weight: 600;
            letter-spacing: -0.031rem;
          }
        }
      }

      .skeleton_news_title {
        width: 70%;
        font-size: 1.5em;
        font-weight: 800;
        line-height: 1.3;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        word-break: keep-all;
        margin-bottom: 3.84rem;
      }

      .skeleton_news_tag_list {
        display: flex;
        gap: 0.012rem;

        .skeleton_news_tag {
          font-size: 0.81em;
          font-weight: 600;
          letter-spacing: -0.031rem;
          background-color: #383a3a;
          padding: 0.125rem 0.375rem;
          border-radius: 0.25rem;
        }
      }
    }

    .skeleton_news_img_box {
      width: 100%;
      height: 11.55rem; 
      .skeleton_news_prev_img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
}