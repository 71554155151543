.irdownload_form_wrapper {
  background-color: #242424;
  color: #ffffff;
  padding: 0 0 12.5rem 0;

  @include tablet {
    padding: 86.18px 26px;
  }
  @include tabletToMobile {
    padding: 86.18px 26px;
  }

  .irdownload_inner {
    width: 1200.03px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    @include laptop {
      width: 1000px;
    }
    @include tablet {
      width: 100%;
    }

    @include tabletToMobile {
      width: 100%;
    }

    .irdownload_form_box {
      width: 100%;

      .irdownload_title_box {
        display: flex;
        flex-direction: column;
        @include tablet {
          text-align: center;
        }
        @include tabletToMobile {
          text-align: center;
        }

        .irdownload_title {
          font-weight: 600;
          letter-spacing: -0.2083rem;
          font-size: 3.438em;
          @include tablet {
            font-size: 42px;
            letter-spacing: 0;
          }
          @include tabletToMobile {
            font-size: 42px;
            letter-spacing: 0;
          }
        }

        .irdownload_sub_title {
          margin-top: 1.606rem;
          margin-bottom: 4.226rem;
          font-weight: 600;
          font-size: 1.813em;
          @include tablet {
            font-size: 27px;
            font-weight: 500;
          }
          @include tabletToMobile {
            font-size: 27px;
            font-weight: 500;
          }
          @include mobile {
            font-size: 1em;
          }
        }
      }

      .irdownload_form_inner {
        width: 100%;
        display: flex;
        margin-bottom: 2.125rem;
        @include tablet {
          flex-direction: column;
          margin-bottom: 0;
        }
        @include tabletToMobile {
          flex-direction: column;
          margin-bottom: 0;
        }

        .irdownload_label_box {
          width: 9.625rem;
          height: 2.6625rem;
          display: flex;
          align-items: end;
          padding-top: 0;
          white-space: pre-wrap;
          @include tablet {
            width: 100%;
          }
          @include tabletToMobile {
            width: 100%;
          }

          .irdownload_label {
            width: 100%;
            display: block;
            font-weight: 700;
            margin-bottom: 0.5rem;
            @include tablet {
              margin-bottom: 24.36px;
              font-size: 24px;
              font-weight: 600;
            }
            @include tabletToMobile {
              margin-bottom: 24.36px;
              font-size: 24px;
              font-weight: 600;
            }
            @include mobile {
              font-size: 1.1em;
              margin-bottom: 20px;
            }
          }

          .irdownload_label_span {
            font-weight: 700;
            color: #de5d25;
          }
        }

        .irdownload_input_box {
          width: 65.8125rem;
          height: 2.6625rem;
          border-bottom: none;
          @include tablet {
            margin-bottom: 45.12px;
            height: 100%;
            width: 100%;
          }
          @include tabletToMobile {
            margin-bottom: 45.12px;
            height: 100%;
            width: 100%;
          }
          @include mobile {
            margin-bottom: 30px;
          }

          &.irdownload_with_border {
            border-bottom: 0.0625rem solid #ffffff;
            @include tablet {
              border-bottom: none;
            }
            @include tabletToMobile {
              border-bottom: none;
            }
          }

          .irdownload_input_field {
            width: 100%;
            background-color: #242424;
            color: #ffffff;
            font-size: 0.9375em;
            height: 100%;
            padding: 0.625rem 0.9375rem;
            border: none;
            @include tablet {
              font-size: 24px;
              border: 1px solid #fff;
              border-radius: 0.5rem;
              padding: 24px 23px;
            }
            @include tabletToMobile {
              font-size: 24px;
              border: 1px solid #fff;
              border-radius: 0.5rem;
              padding: 24px 23px;
            }
            @include mobile {
              font-size: 1.1em;
              padding: 18px 17px;
            }
          }
        }
      }

      .irdownload_email_input_box {
        width: 65.8125rem;
        display: flex;
        align-items: center;
        @include tablet {
          flex-direction: column;
          margin-bottom: 45.12px;
          width: 100%;
        }
        @include tabletToMobile {
          flex-direction: column;
          margin-bottom: 45.12px;
          width: 100%;
        }
        @include mobile {
          margin-bottom: 40px;
        }

        .email_input_container {
          display: flex;
          align-items: center;
          width: 100%;
          @include tablet {
            flex-direction: row;
            .irdownload_email_input {
              height: 76px;
              flex: 1;
              max-width: 45%;
            }

            .custom_select_container {
              flex: 1;
              max-width: 55%;
              min-width: 150px;
            }
          }
          @include tabletToMobile {
            flex-direction: row;
            .irdownload_email_input {
              height: 76px;
              flex: 1;
              max-width: 45%;
            }

            .custom_select_container {
              flex: 1;
              max-width: 55%;
              min-width: 150px;
            }
          }
          @include mobile {
            .irdownload_email_input {
              font-size: 1.1em;
              height: 60px;
              flex: 1;
              max-width: 45%;
            }

            .custom_select_container {
              flex: 1;
              max-width: 55%;
              min-width: 150px;
            }
          }
        }

        .irdownload_email_input {
          flex: 1;
          background-color: #242424;
          padding: 0.625rem 0.9375rem;
          border: none;
          font-size: 0.9375em;
          border-bottom: 0.0625rem solid #ffffff;
          color: #ffffff;
          @include tablet {
            flex: 50%;
            font-size: 26px;
            border: 1px solid #fff;
            border-radius: 0.5rem;
            padding: 24px 23px;
          }
          @include tabletToMobile {
            flex: 50%;
            font-size: 26px;
            border: 1px solid #fff;
            border-radius: 0.5rem;
            padding: 24px 23px;
          }
        }

        .irdownload_email_span {
          font-size: 1.3rem;
          margin: 0 0.5rem;
          @include tablet {
            margin: 0 19.29px;
            font-size: 28.465px;
            font-weight: 600;
          }
          @include tabletToMobile {
            margin: 0 19.29px;
            font-size: 28.465px;
            font-weight: 600;
          }
          @include mobile {
            margin: 0 6px;
            font-size: 1.4em;
          }
        }

        .irdownload_email_input_inner {
          display: flex;
          flex-direction: row;
          gap: 1.875rem;
          align-items: center;
          @include tablet {
            flex-direction: column;
            gap: 0.625rem;
            width: 100%;
          }
          @include tabletToMobile {
            flex-direction: column;
            gap: 0.625rem;
            width: 100%;
          }

          .irdownload_email_input_current {
            width: 21.3125rem;
            height: 2.6625rem;
            font-size: 0.9375em;
            border: 0.0833rem solid #ffffff;
            border-radius: 0.5rem;
            background-color: #383a3a;
            padding-left: 0.9375rem;
            color: #ffffff;
            @include tablet {
              width: 100%;
              font-size: 26px;
              border: 1px solid #fff;
              border-radius: 0.5rem;
              padding: 24px 23px;
            }
            @include tabletToMobile {
              width: 100%;
              font-size: 26px;
              border: 1px solid #fff;
              border-radius: 0.5rem;
              padding: 24px 23px;
            }
            &:disabled {
              background-color: #383a3a;
            }
          }

          .irdownload_custom_select_box {
            width: 19.375rem;
            z-index: 1;
            @include tablet {
              width: 100%;
              font-size: 26px;
              border: 1px solid #fff;
              border-radius: 0.5rem;
              padding: 24px 23px;
            }
            @include tabletToMobile {
              width: 100%;
              font-size: 26px;
              border: 1px solid #fff;
              border-radius: 0.5rem;
              padding: 24px 23px;
            }
          }
        }
      }
    }
  }
}

.irdownload_button_box {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 62.5px 0 15px 0;

  .irdownload_submit_button {
    color: #ffffff;
    background-color: #242424;
    border: 0.05rem solid #ffffff;
    border-radius: 0.5rem;
    cursor: pointer;
    font-weight: 600;
    font-size: 1.063em;
    transition: 0.3s ease;
    padding: 0.938rem 4.289rem;
    width: 100%;
    @include tablet {
      flex: 1;
      font-size: 28.5px;
      font-weight: 600;
      margin-top: 0;
      padding: 23.16px 0;
    }
    @include tabletToMobile {
      flex: 1;
      font-size: 28.5px;
      font-weight: 600;
      margin-top: 0;
      padding: 23.16px 0;
    }
    @include mobile {
      font-size: 18px;
      padding: 16px 0;
    }

    &:hover {
      border: 0.05rem solid #383a3a;
      background-color: #ffffff;
      color: #242424;
    }
  }
}

.irdownload_bold {
  font-weight: 800;
}

.irdownload_checkbox_wrapper {
  display: block;
  width: 100%;
  border-radius: 1.375rem;
  background-color: #383a3a;
  padding: 2.375rem 7.188rem;
  @include tablet {
    background-color: transparent;
    padding: 0;
    margin: 3rem 0 0 0;
  }
  @include tabletToMobile {
    background-color: transparent;
    padding: 0;
    margin: 3rem 0 0 0;
  }
  @include mobile {
    margin: 2rem 0 0 0;
  }

  .irdownload_privacy_policy_content {
    font-weight: 600;
    font-size: 0.9375em;
    line-height: 2;

    &.irdownload_subtitle {
      font-weight: 700;
      font-size: 0.875em;
    }

    &.irdownload_content_title {
      font-weight: 600;
      font-size: 0.9375em;
      margin-bottom: 1.03125rem;
    }

    &.irdownload_warning {
      font-weight: 600;
      font-size: 0.9375em;
      margin-top: 1.03125rem;
    }
  }

  .irdownload_checkbox_box {
    display: flex;
    align-items: center;
    margin-bottom: 2.375rem;
    position: relative;
    @include tablet {
      margin-bottom: 0;
    }
    @include tabletToMobile {
      margin-bottom: 0;
    }

    .irdownload_checkbox_input {
      display: none;
    }

    .irdownload_custom_checkbox {
      width: 1.1875rem;
      height: 1.1875rem;
      border: 0.0833rem solid #ffffff;
      border-radius: 0.625rem;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 0.53125rem;
      background-color: #383a3a;
      position: relative;
      cursor: pointer;
      @include tablet {
        width: 31.756px;
        height: 31.8px;
        border-radius: 50%;
      }
      @include tabletToMobile {
        width: 31.756px;
        height: 31.8px;
        border-radius: 50%;
      }
      @include mobile {
        min-width: 20px;
        max-width: 20px;
        max-height: 20px;
        min-height: 20px;
      }

      &.irdownload_checked {
        background-color: #ffffff;

        &::after {
          content: "";
          display: block;
          width: 0.28125rem;
          height: 0.59375rem;
          border: 0.3125rem solid #050303;
          border-width: 0 0.225rem 0.225rem 0;
          transform: rotate(45deg);
          position: absolute;
          left: 0.3125rem;
          top: 0.1rem;
          @include tablet {
            width: 0.48125rem;
            height: 0.89375rem;
            border-width: 0 0.225rem 0.225rem 0;
            left: 0.6125rem;
            top: 0.325rem;
          }
          @include tabletToMobile {
            width: 0.48125rem;
            height: 0.89375rem;
            border-width: 0 0.225rem 0.225rem 0;
            left: 0.6125rem;
            top: 0.325rem;
          }
          @include mobile {
            width: 0.3rem;
            height: 0.7rem;
            left: 0.3rem;
            top: 0.1rem;
          }
        }
      }
    }

    .irdownload_checkbox_label {
      display: block;
      font-weight: 600;
      font-size: 0.9375em;
      cursor: pointer;
      color: #ffffff;
      @include tablet {
        font-size: 24px;
        font-weight: 500;
      }
      @include tabletToMobile {
        font-size: 24px;
        font-weight: 500;
      }
      @include mobile {
        font-size: 16px;
        font-weight: 500;
      }
    }

    .irdownload_view_details_button {
      margin-left: 1rem;
      background: none;
      border: none;
      color: #fff;
      cursor: pointer;
      font-size: 1em;
      text-decoration: underline;
      white-space: nowrap;
      @include tablet {
        font-size: 23.5px;
        font-style: normal;
        font-weight: 500;
        margin-left: auto;
      }
      @include tabletToMobile {
        font-size: 23.5px;
        font-style: normal;
        font-weight: 500;
        margin-left: auto;
      }
      @include mobile {
        font-size: 16px;
        font-weight: 500;
      }

      &:hover {
        color: #ff8c42;
      }
    }
  }

  .irdownload_privacy_policy_grid {
    border: 0.05rem solid #ffffff;
    width: 100%;
    height: auto;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: auto;
    margin: 1.181rem 0;

    .irdownload_privacy_policy_grid_item {
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 0.05rem solid #ffffff;
      padding: 0.75rem;

      &.irdownload_collect_purpose,
      &.irdownload_collect_term,
      &.irdownload_collect_item_content,
      &.irdownload_collect_purpose_content,
      &.irdownload_collect_term_content {
        height: auto;

        @include tablet {
          padding: 0.75rem;
          font-size: 23.5px;
          font-weight: 500;
          letter-spacing: -1.645px;
        }
        @include tabletToMobile {
          padding: 0.75rem;
          font-size: 23.5px;
          font-weight: 500;
          letter-spacing: -1.645px;
        }
        @include mobile {
          font-size: 16px;
          padding: 0.5rem;
        }
      }
      @include mobile {
        font-size: 16px;
        padding: 0.5rem;
      }
    }
  }

  .irdownload_privacy_policy_content {
    font-weight: 600;
    font-size: 0.9375em;
    line-height: 2;

    &.irdownload_subtitle {
      font-weight: 700;
      font-size: 0.875em;
      @include tablet {
        color: #f7f7f7;
        font-size: 23.5px;
        font-weight: 700;
        letter-spacing: -0.705px;
        margin-bottom: 3px;
      }
      @include tabletToMobile {
        color: #f7f7f7;
        font-size: 23.5px;
        font-weight: 700;
        letter-spacing: -0.705px;
        margin-bottom: 3px;
      }
      @include mobile {
        font-size: 16px;
      }
    }

    &.irdownload_content_title {
      font-weight: 600;
      font-size: 0.9375em;
      margin-bottom: 1.03125rem;
      @include tablet {
        color: #f9f9fa;
        font-size: 23.5px;
        font-weight: 500;
        letter-spacing: -0.235px;
        line-height: 1.5;
      }
      @include tabletToMobile {
        color: #f9f9fa;
        font-size: 23.5px;
        font-weight: 500;
        letter-spacing: -0.235px;
        line-height: 1.5;
      }
      @include mobile {
        font-size: 16px;
      }
    }

    &.irdownload_warning {
      font-weight: 600;
      font-size: 0.9375em;
      margin-top: 1.03125rem;
      @include tablet {
        font-size: 23.5px;
        font-weight: 500;
        letter-spacing: -0.235px;
        line-height: 1.5;
      }
      @include tabletToMobile {
        font-size: 23.5px;
        font-weight: 500;
        letter-spacing: -0.235px;
        line-height: 1.5;
      }
      @include mobile {
        font-size: 16px;
      }
    }
  }

  .irdownload_privacy_policy_grid {
    border: 0.05rem solid #ffffff;
    width: 100%;
    height: auto;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: auto;
    margin: 1.181rem 0;
    @include tablet {
      border: 1px solid #ffffff;
    }
    @include tabletToMobile {
      border: 1px solid #ffffff;
    }

    .irdownload_privacy_policy_grid_item {
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 0.08rem solid #ffffff;
      padding: 0.75rem;

      &.irdownload_collect_purpose,
      &.irdownload_collect_term,
      &.irdownload_collect_item_content,
      &.irdownload_collect_purpose_content,
      &.irdownload_collect_term_content {
        height: auto;
      }

      &.irdownload_collect_item_content,
      &.irdownload_collect_purpose_content,
      &.irdownload_collect_term_content {
        line-height: 1.6;
      }

      &.irdownload_collect_term_content {
        font-weight: 700;
        @include tablet {
          font-size: 28.5px;
        }
        @include tabletToMobile {
          font-size: 28.5px;
        }
        @include mobile {
          font-size: 16px;
        }
      }
      @include tablet {
        padding: 0.75rem;
        font-size: 23.5px;
        font-weight: 500;
        letter-spacing: -1.645px;
        border: 1px solid #ffffff;
      }
      @include tabletToMobile {
        padding: 0.75rem;
        font-size: 23.5px;
        font-weight: 500;
        letter-spacing: -1.645px;
        border: 1px solid #ffffff;
      }
      @include mobile {
        font-size: 16px;
      }
    }
  }

  .modal_download_button_box {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 0 1rem;

    .modal_download_button {
      flex: 1;
      background-color: #de5d25;
      color: #ffffff;
      border: none;
      border-radius: 0.5rem;
      padding: 0.75rem 1.5rem;
      font-size: 1em;
      font-weight: 600;
      cursor: pointer;
      transition: background-color 0.3s ease;

      &:hover {
        background-color: #ff8c42;
      }
      @include tablet {
        font-size: 1.2em;
        padding: 1rem 2rem;
      }
      @include tabletToMobile {
        font-size: 1.2em;
        padding: 1rem 2rem;
      }
    }
  }
}

.modal_overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10000;
}

.modal_content {
  background: #242424;
  color: #ffffff;
  padding: 1.5rem;
  width: 100%;
  position: relative;

  .modal_irdownload_checkbox_box {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
    position: relative;
    padding: 15px 0 30px 0;
    border-bottom: 1px solid #fff;
    @include mobile {
      padding: 5px 0 20px 0;
    }

    .modal_irdownload_checkbox_input {
      display: none;
    }

    .modal_irdownload_custom_checkbox {
      width: 31.756px;
      height: 31.8px;
      border: 0.0833rem solid #ffffff;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 0.53125rem;
      background-color: #383a3a;
      position: relative;
      cursor: pointer;

      @include mobile {
        min-width: 20px;
        max-width: 20px;
        max-height: 20px;
        min-height: 20px;
      }

      &.modal_irdownload_checked {
        background-color: #ffffff;

        &::after {
          content: "";
          display: block;
          width: 0.48125rem;
          height: 0.89375rem;
          border: 0.2125rem solid #050303;
          border-width: 0 0.225rem 0.225rem 0;
          transform: rotate(45deg) translate(-50%, -50%);
          position: absolute;
          top: 50%;
          left: 50%;

          @include mobile {
            width: 0.3rem;
            height: 0.7rem;
            top: 45%;
            left: 15%;
          }
        }
      }
    }

    .modal_irdownload_checkbox_label {
      display: block;
      font-size: 23.5px;
      font-weight: 500;
      cursor: pointer;
      color: #ffffff;
      @include mobile {
        font-size: 16px;
      }
    }

    .modal_irdownload_view_details_button {
      margin-left: auto;
      background: none;
      border: none;
      color: #fff;
      cursor: pointer;
      font-size: 23.5px;
      font-weight: 500;
      text-decoration: underline;
      white-space: nowrap;
      @include mobile {
        font-size: 16px;
      }
    }
  }
}
