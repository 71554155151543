.header_wrapper {
  width: 100%;
  height: 7rem;
  background-color: #090606;
  position: fixed;
  top: 0;
  left: 0;
  color: #ffffff;
  z-index: 9999;
  transition: transform 0.1s cubic-bezier(0.4, 0, 0.2, 1);
  transform: translateY(0);

  &.hidden {
    transform: translateY(-100%);
  }

  &.visible {
    transform: translateY(0);
  }

  .header_inner {
    width: 90%;
    height: 100%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: 600;
    font-size: 1em;
    letter-spacing: 0.05rem;
    position: relative;
    z-index: 10000;

    .logo_box {
      display: flex;
      align-items: center;
      text-decoration: none;

      .logo_img {
        width: 8.426rem;
        height: 2.315rem;
      }
    }

    .burger_box {
      gap: 67.38px;
      display: flex;
      align-items: center;

      .burger_menu {
        background: none;
        border: none;
        cursor: pointer;
        padding: 0;
        z-index: 1001;
        width: 38.5px;
        height: 28px;
        position: relative;

        .bar {
          display: block;
          width: 100%;
          height: 0.2rem;
          background-color: #ffffff;
          border-radius: 0.15rem;
          transition: all 0.3s ease;
          position: absolute;
          left: 0;
        }

        .bar:nth-child(1) {
          top: 0rem;
        }

        .bar:nth-child(2) {
          top: 0.7rem;
        }

        .bar:nth-child(3) {
          top: 1.4rem;
        }

        &.burger_open {
          .bar:nth-child(1) {
            transform: rotate(45deg);
            top: 0.7rem;
          }

          .bar:nth-child(2) {
            opacity: 0;
          }

          .bar:nth-child(3) {
            transform: rotate(-45deg);
            top: 0.7rem;
          }
        }
      }
    }
    .nav_tool_box {
      display: flex;
      gap: 10vw;
      .nav_box {
        display: flex;
        align-items: center;
        gap: 8.65vw;
        white-space: nowrap;

        .nav_item {
          padding: 0.2rem 0;
          color: #ffffff;
          text-decoration: none;
          transition: all 0.3s ease;
          position: relative;
          letter-spacing: 0.01rem;

          &::after {
            content: "";
            position: absolute;
            width: 0;
            height: 1px;
            background-color: #ffffff;
            left: 0;
            bottom: -0.25rem;
            transition: width 0.3s ease;
          }

          &:hover::after {
            width: 100%;
          }

          &.active::after {
            width: 100%;
          }
        }
      }
      .tool_box {
        width: 11rem;
        display: flex;
        align-items: center;
        justify-content: end;
        gap: 0.24rem;
        font-weight: 600;

        .inquiry {
          padding: 0.688rem;
          color: #ffffff;
          text-decoration: none;
          transition: color 0.3s ease;
          letter-spacing: 0.01rem;

          &:hover,
          &.active {
            color: #de5d25;
          }
        }

        .separator {
          margin: 0 0.5rem;
          color: #ffffff;
        }

        .translation_button {
          border: none;
          background-color: transparent;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          position: relative;
          padding: 0;

          .trans_svg {
            width: 2.88rem;
            height: 2.4rem;
            fill: #ffffff;
            transition: fill 0.3s ease;
            margin-left: 0.875rem;
            &:hover {
              fill: #de5d25;
            }
          }
        }
      }
    }
  }

  .burger_menu_list_box {
    position: absolute;
    top: 7rem;
    left: 0;
    width: 100%;
    height: calc(100vh - 7rem);
    background-color: #222222;
    border-top: 1px solid #383a3a;
    transform: translateX(-100%);
    transition: transform 0.3s ease-in-out;

    .burger_menu_list {
      display: flex;
      flex-direction: column;
      margin-top: 3rem;

      .burger_menu_item {
        a {
          display: block;
          padding: 3rem 2rem;
          font-size: 43.5px;
          font-weight: 600;
          letter-spacing: -2.61px;
        }
      }
    }

    &.active {
      transform: translateX(0);
    }
  }

  .modal_overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background: transparent;
    z-index: 10001;
  }

  .modal_container {
    position: fixed;
    top: 5rem;
    right: 3rem;
    background-color: #ffffff;
    border-radius: 0.1rem;
    box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.2);
    display: flex;
    flex-direction: column;
    z-index: 10002;
    padding: 1rem;
  }

  .modal_option {
    position: relative;
    width: 100%;
    padding: 0.9rem 2.2rem 0.9rem 0.7rem;
    display: flex;
    align-items: center;
    justify-content: start;
    gap: 0.25rem;
    background-color: transparent;
    border: none;
    text-align: left;
    font-size: 1em;
    cursor: pointer;
    color: #222222;
    transition: background-color 0.3s ease, color 0.3s ease;
    letter-spacing: -0.04rem;

    &:first-child {
      padding-bottom: 0;
    }

    &:last-child {
      padding-top: 0.9rem;
    }

    .icon_wrapper {
      width: 1.5rem;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    span {
      font-weight: 600;
    }

    &:hover {
      background-color: #f0f0f0;
    }
  }
}

.trans_lang_svg {
  width: 40px;
  height: 40px;
}

@include laptop {
  .header_wrapper {
    .header_inner {
      .nav_tool_box {
        display: flex;
        gap: 6vw;
        .nav_box {
          display: flex;
          align-items: center;
          gap: 5vw;
          white-space: nowrap;
        }
      }
    }
  }
}

@include tablet {
  .header_wrapper {
    background-color: #222222;

    .header_inner {
      background-color: #222222;
      padding: 0 29.35px;
      width: 100%;

      .logo_box {
        .logo_img {
          width: 9.996rem;
          height: 2.764rem;
        }
      }

      .burger_box {
        .burger_menu {
          order: 1;
        }
      }
      .nav_tool_box {
        .nav_box {
          position: fixed;
          top: 7rem;
          left: 0;
          width: 100%;
          height: calc(100vh - 7rem);
          background-color: #090606;
          flex-direction: column;
          align-items: center;
          justify-content: start;
          padding-top: 2rem;
          gap: 2rem;
          transform: translateY(-100%);
          transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
          opacity: 0;
          overflow-y: auto;
          z-index: 1000;

          .nav_item {
            font-size: 1.2em;
            gap: 0;
          }
        }
        .tool_box {
          flex-direction: column;
          gap: 1rem;

          .separator {
            display: none;
          }
        }
      }
    }
    .modal_container {
      position: fixed;
      top: 6rem;
      right: 5rem;
      background-color: #ffffff;
      border-radius: 0.1rem;
      box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.2);
      display: flex;
      flex-direction: column;
      z-index: 10002;
      padding: 1rem;
    }
  }
}

@include tabletToMobile {
  .header_wrapper {
    background-color: #222222;

    .header_inner {
      background-color: #222222;
      padding: 0 29.35px;
      width: 100%;

      .logo_box {
        .logo_img {
          width: 9.996rem;
          height: 2.764rem;
        }
      }

      .burger_box {
        .burger_menu {
          order: 1;
        }
      }
      .nav_tool_box {
        .nav_box {
          position: fixed;
          top: 7rem;
          left: 0;
          width: 100%;
          height: calc(100vh - 7rem);
          background-color: #090606;
          flex-direction: column;
          align-items: center;
          justify-content: start;
          padding-top: 2rem;
          gap: 2rem;
          transform: translateY(-100%);
          transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
          opacity: 0;
          overflow-y: auto;
          z-index: 1000;

          .nav_item {
            font-size: 1.2em;
            gap: 0;
          }
        }
        .tool_box {
          flex-direction: column;
          gap: 1rem;

          .separator {
            display: none;
          }
        }
      }
    }
  }
}

@include mobile {
  .header_wrapper {
    height: 5rem;

    .header_inner {
      .logo_box {
        .logo_img {
          width: 7rem;
          height: 2rem;
        }
      }

      .burger_box {
        gap: 30px;

        .burger_menu {
          width: 33px;
          height: 26px;
        }
      }
    }

    .burger_menu_list_box {
      top: 5rem;
      height: calc(100vh - 5rem);
      .burger_menu_list {
        .burger_menu_item {
          a {
            padding: 2rem 2rem;
            font-size: 1.7em;
            letter-spacing: -2px;
          }
        }
      }
    }
  }

  .trans_lang_svg {
    width: 26px;
    height: 26px;
  }
}
