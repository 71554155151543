@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(1.25rem);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.main_content {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  background-color: #222222;
  color: #ffffff;
  overflow-x: hidden;
  position: relative;
  z-index: 1;

  .content_box {
    box-sizing: border-box;
    padding: 10.313rem 0;
    display: flex;
    flex-direction: column;

    &:nth-child(2) {
      padding: 0 0 13.4rem 0;
    }

    .content_inner {
      width: 1200.03px;
      margin: 0 auto;

      .title {
        font-size: 3.438em;
        font-weight: 600;
        letter-spacing: -0.156rem;
        margin-bottom: 1.56rem;
      }

      .mobile_title {
        margin-bottom: 86.85px;
      }

      .sub_title {
        font-size: 1.813em;
        font-weight: 600;
        letter-spacing: -0.0625rem;
        margin-bottom: 3.375rem;
      }

      .main_slider_controls {
        display: flex;
        gap: 31.62px;
        margin-bottom: 1rem;
        align-items: center;

        .main_slide_btn_box {
          display: flex;
          gap: 71.77px;

          .main_slide_btn {
            &:disabled {
              opacity: 0.5;
              cursor: not-allowed;
            }
          }
        }

        .main_slide_pagination {
          font-size: 23.5px;
          color: #999899;
          margin-bottom: 0.2rem;
        }
      }

      .main_item_box {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;

        .item {
          width: 20.8rem;
          height: 26.88rem;
          display: flex;
          flex-direction: column;
          border: 0.125rem solid #383a3a;
          border-radius: 1.375rem;
          padding: 3.641rem 1.8rem 2.724rem 1.8rem;

          .item_title {
            font-weight: 700;
            font-size: 1.875em;
            margin-bottom: 0.458rem;
            letter-spacing: -0.031rem;
          }

          .item_sub_title {
            font-weight: 600;
            font-size: 0.875em;
            margin-bottom: 1.2rem;
          }

          .item_link {
            font-weight: 600;
            font-size: 1.116em;
            cursor: pointer;
            margin-top: auto;
            color: #ffffff;
            display: flex;
            align-items: center;

            .item_link_inner {
              border-bottom: 2px solid #fff;
              padding-bottom: 0.2rem;
            }

            svg {
              margin-bottom: 0.188rem;
              margin-left: 0.188rem;
            }
          }
        }
      }

      .button_group {
        margin-bottom: 3.639rem;
        display: flex;
        gap: 4.25rem;

        .button {
          color: #898c8c;
          font-size: 1.813em;
          font-weight: 600;
          letter-spacing: -0.0625rem;
          background-color: transparent;
          border: none;
          cursor: pointer;
          position: relative;
          padding: 0;
          outline: none;

          &:after {
            content: "";
            position: absolute;
            width: 0;
            height: 0.1rem;
            background-color: #ffffff;
            left: 0;
            bottom: -0.25rem;
            transition: width 0.3s ease;
          }

          &:hover {
            color: #ffffff;

            &:after {
              width: 100%;
            }
          }

          &.active {
            color: #ffffff;

            &:after {
              width: 100%;
            }
          }
        }
      }

      .content_container {
        display: flex;
        gap: 3.151rem;

        .content_image {
          width: 20.8rem;
          height: 22.5rem;
          object-fit: cover;
          border-radius: 1.375rem;
          border: 0.125rem solid #383a3a;
        }

        .content_text_box {
          width: 100%;
          display: flex;
          flex-direction: column;
          gap: 2.366rem;
          padding: 0.5rem 0;

          .content_text {
            display: flex;
            flex-direction: column;
            justify-content: center;

            .content_title {
              font-size: 1.531em;
              font-weight: 700;
              letter-spacing: -0.0625rem;
              margin-bottom: 0.6rem;
              border-bottom: 0.125rem solid #383a3a;
              padding-bottom: 0.81rem;
            }

            .content_description_paragraph,
            .content_description_list {
              padding: 0.188rem 0;
              font-size: 1em;
              font-weight: 500;
              white-space: pre-wrap;
              line-height: 1.5;
            }

            .content_description_list {
              list-style: disc;
              padding-left: 2rem;
            }
          }
        }
      }
    }
  }

  .app_box {
    position: relative;

    .app_img {
      width: 100%;
      height: auto;
    }

    .app_content_box {
      position: absolute;
      top: 50%;
      right: 2%;
      transform: translateY(-50%);
      display: flex;
      flex-direction: column;
      color: #222222;
      width: 50%;

      .app_title,
      .app_sub_title,
      .app_btn {
        opacity: 0;
        transform: translateY(1.25rem);
        background-color: transparent;
      }

      .app_title.visible {
        animation: fadeInUp 2s forwards 0.4s;
      }

      .app_sub_title.visible {
        animation: fadeInUp 2s forwards 0.6s;
      }

      .app_btn.visible {
        animation: fadeInUp 2s forwards 0.8s;
      }

      .app_title {
        margin-bottom: 1.396rem;
        font-weight: 700;
        font-size: 2.375em;
        text-align: center;
      }

      .app_sub_title {
        margin-bottom: 3.738rem;
        font-weight: 500;
        font-size: 1.563em;
        text-align: center;
        line-height: 1.2;
        white-space: pre-wrap;
      }

      .app_btn_box {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 1.252rem;

        .app_btn {
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 0.458rem;
          width: 12.36rem;
          height: 3.73rem;
          border: 0.125rem solid #383a3a;
          border-radius: 0.20625rem;
          cursor: pointer;
          transition: box-shadow 0.3s ease;

          &:hover {
            box-shadow: 0.0625rem 0.0625rem 0.312rem rgba(0, 0, 0, 0.5);
          }

          .app_btn_text {
            font-size: 1.313em;
            font-weight: 500;
          }
        }
      }
    }
  }

  .news_box {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(18.75rem, 1fr));
    gap: 0.625rem;

    .news_item {
      display: flex;
      flex-direction: column;
      border: 0.125rem solid #383a3a;
      border-radius: 1.375rem;
      overflow: hidden;
      background-color: #222222;
      color: #ffffff;
      text-decoration: none;

      .news_content {
        display: flex;
        flex-direction: column;
        padding: 1.52rem 1.5rem;

        .news_date {
          display: flex;
          justify-content: space-between;
          margin-bottom: 0.96rem;

          div {
            display: flex;
            align-items: flex-end;
            gap: 0.012rem;

            span {
              font-size: 0.75em;
              font-weight: 600;
              letter-spacing: -0.031rem;
            }
          }
        }

        .news_title {
          width: 70%;
          font-size: 1.5em;
          font-weight: 800;
          line-height: 1.3;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
          word-break: keep-all;
          margin-bottom: 3.84rem;
        }

        .news_tag_list {
          display: flex;
          gap: 0.012rem;

          .news_tag {
            font-size: 0.81em;
            font-weight: 600;
            letter-spacing: -0.031rem;
            background-color: #383a3a;
            padding: 0.125rem 0.375rem;
            border-radius: 0.25rem;
          }
        }
      }

      .news_img_box {
        width: 100%;
        height: 11.55rem;
        display: flex;
        justify-content: center;

        .news_prev_img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          &.edit {
            min-width: 110%;
            height: 110%;
            margin-right: 10px;
          }
        }
      }
    }
  }

  .goto_news_btn {
    background: none;
    color: #ffffff;
    margin: 4.375rem auto 0 auto;
    border: 0.0625rem solid #ffffff;
    border-radius: 0.20625rem;
    width: 16.64rem;
    height: 2.98rem;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.188rem;
    font-size: 1.063em;
    font-weight: 700;
    cursor: pointer;
    transition: all 0.3s ease;
    text-decoration: none;

    &:hover {
      border: 0.0625rem solid #383a3a;
      background-color: #ffffff;
      color: #222222;
    }
  }

  .overlay_container {
    position: relative;
    width: 100%;
    height: 50rem;
    overflow: hidden;

    .overlay_img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    .overlay_text {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate3d(-50%, -50%, 0);
      font-weight: 900;
      font-size: 6.375em;
      white-space: nowrap;
    }

    .left_overlay,
    .right_overlay {
      position: absolute;
      top: 0;
      width: 55.625rem;
      height: 100%;
      background-color: #222222;
    }

    .left_overlay {
      left: 0;
    }

    .right_overlay {
      right: 0;
    }
  }

  .last_content {
    padding: 12.362rem 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;

    .last_title,
    .last_sub_title_span,
    .inquiry_btn {
      opacity: 0;
      transform: translateY(1.25rem);
    }

    .last_title.visible {
      animation: fadeInUp 2s forwards 0.2s;
    }

    .last_sub_title_span.visible {
      animation: fadeInUp 2s forwards 0.4s;
    }

    .last_sub_title_span.delay_06 {
      animation-delay: 0.6s;
    }

    .last_sub_title_span.delay_07 {
      animation-delay: 0.8s;
    }

    .inquiry_btn.visible {
      animation: fadeInUp 2s forwards 0.6s;
    }

    .last_title {
      font-weight: 900;
      font-size: 3.688em;
      margin-bottom: 2.171rem;
    }

    .last_sub_title {
      font-weight: 700;
      font-size: 1.938em;
      margin-bottom: 2.669rem;
      line-height: 1.4;

      .last_sub_title_span {
        display: inline-block;
      }
    }

    .inquiry_btn {
      font-size: 1.31em;
      font-weight: 600;
      color: #222222;
      width: 12.24rem;
      height: 3.64rem;
      display: flex;
      justify-content: center;
      align-items: center;
      border: 0.125rem solid #383a3a;
      border-radius: 0.20625rem;
      background-color: #ffffff;
      cursor: pointer;
      text-decoration: none;
    }
  }

  .main_slider_mode {
    display: flex;
    flex-wrap: nowrap;
    overflow: hidden;
    .item {
      width: calc(100% / 3);
      box-sizing: border-box;
    }
  }

  .mobile_platform_section {
    display: flex;
    flex-direction: column;
    gap: 12.84px;
  }

  .mobile_platform_box {
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }

  .mobile_platform_button {
    box-sizing: border-box;
    position: relative;
    width: 100%;
    height: 184px;
    overflow: hidden;
    border-radius: 20px;
    padding: 0;
    margin: 0;
    background: none;
    border: none;
    cursor: pointer;
  }

  .mobile_platform_button img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    margin: 0;
    padding: 0;
  }

  .mobile_platform_title_box {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    gap: 36.13px;
    padding: 35.52px 42.87px;
    width: 100%;
    height: 100%;
    background-color: rgba(128, 128, 128, 0.5);
    color: #fff;
    transition: background-color 0.4s ease, color 0.4s ease;
  }
  &.active .mobile_platform_title_box {
    background-color: rgba(128, 128, 128, 0);
    color: #222;
  }

  .mobile_active {
    background-color: rgba(128, 128, 128, 0);
    color: #222;
  }

  .mobile_platform_title {
    text-align: start;
    font-size: 32px;
  }

  .mobile_platform_text {
    height: 0;
    opacity: 0;
    overflow: hidden;
    transition: height 0.4s ease, opacity 0.4s ease;
    transition-delay: 0;

    .mobile_platform_text_inner {
      margin-top: 12.84px;
      padding: 39px;
      border: 1px solid #383a3a;
      border-radius: 10px;
    }

    .content_title {
      font-size: 26.5px;
      font-weight: 700;
      margin-bottom: 10.28px;
    }
    .content_title:nth-of-type(2) {
      margin-top: 43.89px;
    }
    .content_description_paragraph {
      margin-bottom: 1rem;
      font-size: 25px;
      font-weight: 500;
      line-height: 1.6;
    }
    .content_description_list {
      list-style-type: "-";
      margin-left: 0.5rem;
      font-size: 25px;
      line-height: 1.6;
      white-space: pre-wrap;
    }
  }

  /* News 섹션 슬라이드 */
  .main_news_slider_wrapper {
    position: relative;
    width: 100%;
    height: 720px;
    overflow: hidden;
  }

  .main_news_slider_container {
    position: relative;
    width: 100%;
    height: 100%;

    .main_news_slide {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0;
      transition: opacity 0.5s ease-in-out;
      z-index: 1;
    }

    .main_news_slide.active {
      opacity: 1;
      z-index: 2;
    }

    .main_news_slide.loading {
      opacity: 0;
      z-index: 1;
    }
  }

  .main_news_item {
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-rows: 60% 40%;
    border: 0.125rem solid #383a3a;
    border-radius: 1.375rem;
    overflow: hidden;
    background-color: #222222;
    color: #ffffff;
    text-decoration: none;
  }

  .main_news_content {
    padding: 1.52rem 1.5rem;
    display: flex;
    flex-direction: column;

    .main_news_date {
      display: flex;
      justify-content: space-between;
      margin-bottom: 0.96rem;

      div {
        display: flex;
        align-items: flex-end;
        gap: 0.012rem;

        span {
          font-size: 0.75em;
          font-weight: 600;
          letter-spacing: -0.031rem;
        }
      }
    }

    .main_news_title {
      width: 70%;
      font-size: 1.5em;
      font-weight: 800;
      line-height: 1.3;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      word-break: keep-all;
      margin-bottom: 3.84rem;
    }

    .main_news_tag_list {
      display: flex;
      gap: 0.012rem;

      .main_news_tag {
        font-size: 0.81em;
        font-weight: 600;
        letter-spacing: -0.031rem;
        background-color: #383a3a;
        padding: 0.125rem 0.375rem;
        border-radius: 0.25rem;
      }
    }
  }

  .main_news_img_box {
    display: flex;
    justify-content: center;
    position: relative;
    .main_news_prev_img {
      width: 100%;
      height: 100%;
      object-fit: cover;

      &.edit {
        min-width: 110%;
        height: 110%;
        margin-right: 10px;
      }
    }
  }
}
.main_news_progress_bar_wrapper {
  position: relative;
  top: 0;
  width: 100%;
  height: 8px;
  background-color: #383a3a;
  margin-top: 80px;

  .progress_bar_background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #383a3a;
  }

  .progress_bar_indicator {
    position: absolute;
    top: 50%;
    left: 0%;
    width: 33.33%;
    height: 8px;
    background-color: #ffffff;
    transform: translateY(-50%);
    transition: left 0.3s ease;
  }
}
.main_news_slide_btn {
  position: absolute;
  width: 74.75px;
  height: 74.75px;
  background-color: rgba(0, 0, 0, 0.8);
  border-radius: 10px;
}
.main_news_next_slide_btn {
  right: 1rem;
}
.main_news_prev_slide_btn {
  left: 1rem;
}

.go_svg {
  width: 0.44rem;
  height: 0.44rem;
}

.go_svg_2 {
  width: 0.893rem;
  height: 0.893rem;
}

.go_news_svg {
  width: 0.56rem;
  height: 0.56rem;
  margin-bottom: 0.4rem;
  transition: fill 0.3s ease;
}
.apple_svg {
  width: 1.2rem;
  height: 1.32rem;
}

.google_svg {
  width: 1.2rem;
  height: 1.32rem;
}
.elec_svg {
  width: 1.69rem;
  height: 1.69rem;
}
.wallet_svg {
  display: block;
  margin: 0.8rem auto;
  width: 13.54rem;
}

.sto_svg {
  display: block;
  margin: 0.8rem auto;
  width: 10rem;
  height: 12.5rem;
}

.trade_svg {
  display: block;
  margin: 0.8rem auto;
  width: 10rem;
  height: 12.5rem;
}

.platform_drop_svg {
  width: 37px;
  height: 38px;
}

@include laptop {
  .main_content {
    .content_box {
      .content_inner {
        width: 1000px;

        .main_item_box {
          .item {
            width: 20rem;
            height: 26rem;
            padding: 3rem 1.4rem 2.5rem 1.4rem;

            .item_title {
              font-size: 1.7em;
            }

            .item_sub_title {
              font-weight: 600;
              font-size: 0.83em;
            }

            .item_link {
              font-size: 1em;
            }
          }
        }

        .button_group {
          gap: 3.5rem;

          .button {
            color: #898c8c;
            font-size: 1.7em;
          }
        }

        .content_container {
          display: flex;
          gap: 3.151rem;
        }
      }
    }

    .app_box {
      position: relative;

      .app_img {
        width: 100%;
        height: auto;
      }

      .app_content_box {
        .app_title {
          font-size: 2em;
        }

        .app_sub_title {
          font-size: 1.3em;
        }

        .app_btn_box {
          gap: 1.2rem;

          .app_btn {
            gap: 0.458rem;
            width: 11rem;
            height: 3.5rem;

            &:hover {
              box-shadow: 0.0625rem 0.0625rem 0.312rem rgba(0, 0, 0, 0.5);
            }

            .app_btn_text {
              font-size: 1.313em;
              font-weight: 500;
            }
          }
        }
      }
    }

    .news_box {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(18.75rem, 1fr));
      gap: 0.625rem;

      .news_item {
        display: flex;
        flex-direction: column;
        border: 0.125rem solid #383a3a;
        border-radius: 1.375rem;
        overflow: hidden;
        background-color: #222222;
        color: #ffffff;
        text-decoration: none;

        .news_content {
          display: flex;
          flex-direction: column;
          padding: 1.52rem 1.5rem;

          .news_date {
            display: flex;
            justify-content: space-between;
            margin-bottom: 0.96rem;

            div {
              display: flex;
              align-items: flex-end;
              gap: 0.012rem;

              span {
                font-size: 0.75em;
                font-weight: 600;
                letter-spacing: -0.031rem;
              }
            }
          }

          .news_title {
            width: 90%;
            font-size: 1.4em;
          }
        }
      }
    }

    .overlay_container {
      .overlay_text {
        font-size: 4em;
      }
    }

    .last_content {
      padding: 12.362rem 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;

      .last_title,
      .last_sub_title_span,
      .inquiry_btn {
        opacity: 0;
        transform: translateY(1.25rem);
      }

      .last_title.visible {
        animation: fadeInUp 2s forwards 0.2s;
      }

      .last_sub_title_span.visible {
        animation: fadeInUp 2s forwards 0.4s;
      }

      .last_sub_title_span.delay_06 {
        animation-delay: 0.6s;
      }

      .last_sub_title_span.delay_07 {
        animation-delay: 0.8s;
      }

      .inquiry_btn.visible {
        animation: fadeInUp 2s forwards 0.6s;
      }

      .last_title {
        font-weight: 900;
        font-size: 3.688em;
        margin-bottom: 2.171rem;
      }

      .last_sub_title {
        font-weight: 700;
        font-size: 1.938em;
        margin-bottom: 2.669rem;
        line-height: 1.4;

        .last_sub_title_span {
          display: inline-block;
        }
      }

      .inquiry_btn {
        font-size: 1.31em;
        font-weight: 600;
        color: #222222;
        width: 12.24rem;
        height: 3.64rem;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 0.125rem solid #383a3a;
        border-radius: 0.20625rem;
        background-color: #ffffff;
        cursor: pointer;
        text-decoration: none;
      }
    }

    .main_slider_mode {
      display: flex;
      flex-wrap: nowrap;
      overflow: hidden;
      .item {
        width: calc(100% / 3);
        box-sizing: border-box;
      }
    }

    .mobile_platform_section {
      display: flex;
      flex-direction: column;
      gap: 12.84px;
    }

    .mobile_platform_box {
      display: flex;
      flex-direction: column;
      overflow: hidden;
    }

    .mobile_platform_button {
      box-sizing: border-box;
      position: relative;
      width: 100%;
      height: 184px;
      overflow: hidden;
      border-radius: 20px;
      padding: 0;
      margin: 0;
      background: none;
      border: none;
      cursor: pointer;
    }

    .mobile_platform_button img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
      margin: 0;
      padding: 0;
    }

    .mobile_platform_title_box {
      position: absolute;
      top: 0;
      left: 0;
      display: flex;
      flex-direction: column;
      gap: 36.13px;
      padding: 35.52px 42.87px;
      width: 100%;
      height: 100%;
      background-color: rgba(128, 128, 128, 0.5);
      color: #fff;
      transition: background-color 0.4s ease, color 0.4s ease;
    }
    &.active .mobile_platform_title_box {
      background-color: rgba(128, 128, 128, 0);
      color: #222;
    }
    .mobile_active {
      background-color: rgba(128, 128, 128, 0);
      color: #222;
    }

    .mobile_platform_title {
      text-align: start;
      font-size: 32px;
    }

    .mobile_platform_text {
      height: 0;
      opacity: 0;
      overflow: hidden;
      transition: height 0.4s ease, opacity 0.4s ease;
      transition-delay: 0;

      .mobile_platform_text_inner {
        margin-top: 12.84px;
        padding: 39px;
        border: 1px solid #383a3a;
        border-radius: 10px;
      }

      .content_title {
        font-size: 26.5px;
        font-weight: 700;
        margin-bottom: 10.28px;
      }
      .content_title:nth-of-type(2) {
        margin-top: 43.89px;
      }
      .content_description_paragraph {
        margin-bottom: 1rem;
        font-size: 25px;
        font-weight: 500;
        line-height: 1.6;
      }
      .content_description_list {
        list-style-type: "-";
        margin-left: 0.5rem;
        font-size: 25px;
        line-height: 1.6;
        white-space: pre-wrap;
      }
    }

    /* News 섹션 슬라이드 */
    .main_news_slider_wrapper {
      position: relative;
      width: 100%;
      height: 720px;
      overflow: hidden;
    }

    .main_news_slider_container {
      position: relative;
      width: 100%;
      height: 100%;

      .main_news_slide {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
        transition: opacity 0.5s ease-in-out;
        z-index: 1;
      }

      .main_news_slide.active {
        opacity: 1;
        z-index: 2;
      }

      .main_news_slide.loading {
        opacity: 0;
        z-index: 1;
      }
    }

    .main_news_item {
      width: 100%;
      height: 100%;
      display: grid;
      grid-template-rows: 60% 40%;
      border: 0.125rem solid #383a3a;
      border-radius: 1.375rem;
      overflow: hidden;
      background-color: #222222;
      color: #ffffff;
      text-decoration: none;
    }

    .main_news_content {
      padding: 1.52rem 1.5rem;
      display: flex;
      flex-direction: column;

      .main_news_date {
        display: flex;
        justify-content: space-between;
        margin-bottom: 0.96rem;

        div {
          display: flex;
          align-items: flex-end;
          gap: 0.012rem;

          span {
            font-size: 0.75em;
            font-weight: 600;
            letter-spacing: -0.031rem;
          }
        }
      }

      .main_news_title {
        width: 70%;
        font-size: 1.5em;
        font-weight: 800;
        line-height: 1.3;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        word-break: keep-all;
        margin-bottom: 3.84rem;
      }

      .main_news_tag_list {
        display: flex;
        gap: 0.012rem;

        .main_news_tag {
          font-size: 0.81em;
          font-weight: 600;
          letter-spacing: -0.031rem;
          background-color: #383a3a;
          padding: 0.125rem 0.375rem;
          border-radius: 0.25rem;
        }
      }
    }

    .main_news_img_box {
      display: flex;
      justify-content: center;
      position: relative;
      .main_news_prev_img {
        width: 100%;
        height: 100%;
        object-fit: cover;

        &.edit {
          min-width: 110%;
          height: 110%;
          margin-right: 10px;
        }
      }
    }
  }
  .main_news_progress_bar_wrapper {
    position: relative;
    top: 0;
    width: 100%;
    height: 8px;
    background-color: #383a3a;
    margin-top: 80px;

    .progress_bar_background {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: #383a3a;
    }

    .progress_bar_indicator {
      position: absolute;
      top: 50%;
      left: 0%;
      width: 33.33%;
      height: 8px;
      background-color: #ffffff;
      transform: translateY(-50%);
      transition: left 0.3s ease;
    }
  }
  .main_news_slide_btn {
    position: absolute;
    width: 74.75px;
    height: 74.75px;
    background-color: rgba(0, 0, 0, 0.8);
    border-radius: 10px;
  }
  .main_news_next_slide_btn {
    right: 1rem;
  }
  .main_news_prev_slide_btn {
    left: 1rem;
  }

  .go_svg {
    width: 0.44rem;
    height: 0.44rem;
  }

  .go_svg_2 {
    width: 0.893rem;
    height: 0.893rem;
  }

  .go_news_svg {
    width: 0.56rem;
    height: 0.56rem;
    margin-bottom: 0.4rem;
    transition: fill 0.3s ease;
  }
  .apple_svg {
    width: 1.2rem;
    height: 1.32rem;
  }

  .google_svg {
    width: 1.2rem;
    height: 1.32rem;
  }
  .elec_svg {
    width: 1.69rem;
    height: 1.69rem;
  }
  .wallet_svg {
    display: block;
    margin: 0.8rem auto;
    width: 13.54rem;
  }

  .sto_svg {
    display: block;
    margin: 0.8rem auto;
    width: 10rem;
    height: 12.5rem;
  }

  .trade_svg {
    display: block;
    margin: 0.8rem auto;
    width: 10rem;
    height: 12.5rem;
  }
}

@include tablet {
  .main_content {
    .content_box {
      padding: 86.18px 26px;

      &:nth-child(2) {
        padding: 0 26px 86.18px 26px;
      }

      .content_inner {
        width: 100%;

        .title {
          text-align: center;
          font-size: 42px;
          letter-spacing: 1px;
        }

        .sub_title {
          text-align: center;
          margin-bottom: 86.85px;
          font-size: 27px;
          letter-spacing: 1px;
          font-weight: 500;
        }

        .main_item_box {
          align-items: baseline;
          justify-content: start;
          gap: 38px;
          width: 100%;

          .item {
            min-width: 490px;
            height: 633px;
            padding: 5rem 1.8rem 3rem 1.8rem;

            .item_title {
              font-size: 43.5px;
              margin-bottom: 11.79px;
            }

            .item_sub_title {
              font-size: 20px;
              font-weight: 500;
            }

            .item_link {
              font-size: 29px;

              svg {
                font-size: 29px;
              }
            }
          }
        }
      }
    }

    .app_box {
      background-color: #f7f6f6;
      display: flex;
      flex-direction: column-reverse;
      align-items: center;

      .app_content_box {
        width: 100%;
        position: relative;
        top: 0;
        right: 0;
        transform: none;
        padding: 100px 26px 56.18px 26px;

        .app_sub_title {
          line-height: 1.6;
        }

        .app_btn_box {
          .app_btn {
            width: 290px;
            height: 85px;
            gap: 11.49px;

            .app_btn_text {
              font-size: 30px;
            }
          }
        }
      }
    }

    .goto_news_btn {
      width: 445.5px;
      height: 85px;
      font-size: 28.5px;
      font-style: normal;
      font-weight: 500;
    }

    .overlay_container {
      .overlay_text {
        white-space: pre-wrap;
        width: 100%;
        font-size: 53px;
        text-align: center;
        line-height: 1.3;
      }
    }

    .last_content {
      padding: 90.12px 0 97.08px 0;

      .last_title {
        font-size: 47px;
        font-weight: 700;
      }

      .last_sub_title {
        font-size: 25px;
        font-weight: 700;
      }

      .inquiry_btn {
        width: 266.23px;
        height: 79.53px;
        font-size: 28.5px;
        font-weight: 600;
      }
    }

    .main_news_item {
      grid-template-rows: 55% 45%;
    }

    .main_news_content {
      padding: 42.53px 45px;

      .main_news_date {
        font-size: 24px;
        margin-bottom: 25.12px;

        div {
          gap: 9.18px;
        }
      }

      .main_news_title {
        font-size: 38.5px;
        line-height: 51px;
        font-weight: 700;
        white-space: normal;
        margin-bottom: 25.12px;
      }

      .main_news_tag_list {
        margin-top: auto;

        .main_news_tag {
          font-size: 23.5px;
          font-weight: 500;
        }
      }
    }
  }

  .go_svg {
    width: 0.8rem;
    height: 0.8rem;
  }

  .go_svg_2 {
    width: 23.92px;
    height: 23.92px;
  }

  .go_news_svg {
    width: 15.45px;
    height: 15.45px;
  }
  .apple_svg {
    width: 28.97px;
    height: 35.63px;
  }

  .google_svg {
    width: 31.2px;
    height: 34.74px;
  }
  .elec_svg {
    width: 37.44px;
    height: 53.04px;
  }
  .wallet_svg {
    width: 20rem;
  }

  .sto_svg {
    width: 20rem;
    height: 16rem;
    margin: auto;
  }

  .trade_svg {
    width: 20rem;
    height: 16rem;
    margin: auto;
  }
}

@include tabletToMobile {
  .main_content {
    .content_box {
      padding: 86.18px 26px;

      &:nth-child(2) {
        padding: 0 26px 86.18px 26px;
      }

      .content_inner {
        width: 100%;

        .title {
          text-align: center;
          font-size: 42px;
          letter-spacing: 1px;
        }

        .sub_title {
          text-align: center;
          margin-bottom: 86.85px;
          font-size: 27px;
          letter-spacing: 1px;
          font-weight: 500;
        }

        .main_item_box {
          align-items: baseline;
          justify-content: start;
          gap: 38px;
          width: 100%;

          .item {
            min-width: 490px;
            height: 633px;
            padding: 5rem 1.8rem 3rem 1.8rem;

            .item_title {
              font-size: 43.5px;
              margin-bottom: 11.79px;
            }

            .item_sub_title {
              font-size: 20px;
              font-weight: 500;
            }

            .item_link {
              font-size: 29px;

              svg {
                font-size: 29px;
              }
            }
          }
        }
      }
    }

    .app_box {
      background-color: #f7f6f6;
      display: flex;
      flex-direction: column-reverse;
      align-items: center;

      .app_content_box {
        width: 100%;
        position: relative;
        top: 0;
        right: 0;
        transform: none;
        padding: 74.82px 26px 56.18px 26px;

        .app_sub_title {
          line-height: 1.6;
        }

        .app_btn_box {
          .app_btn {
            width: 290px;
            height: 85px;
            gap: 11.49px;

            .app_btn_text {
              font-size: 30px;
            }
          }
        }
      }
    }

    .goto_news_btn {
      width: 445.5px;
      height: 85px;
      font-size: 28.5px;
      font-style: normal;
      font-weight: 500;
    }

    .overlay_container {
      .overlay_text {
        white-space: pre-wrap;
        width: 100%;
        font-size: 53px;
        text-align: center;
        line-height: 1.3;
      }
    }

    .last_content {
      padding: 90.12px 0 97.08px 0;

      .last_title {
        font-size: 47px;
        font-weight: 700;
      }

      .last_sub_title {
        font-size: 25px;
        font-weight: 700;
      }

      .inquiry_btn {
        width: 266.23px;
        height: 79.53px;
        font-size: 28.5px;
        font-weight: 600;
      }
    }

    .main_news_item {
      grid-template-rows: 55% 45%;
    }

    .main_news_content {
      padding: 42.53px 45px;

      .main_news_date {
        font-size: 24px;
        margin-bottom: 25.12px;

        div {
          gap: 9.18px;
        }
      }

      .main_news_title {
        font-size: 38.5px;
        line-height: 51px;
        font-weight: 700;
        white-space: normal;
        margin-bottom: 25.12px;
      }

      .main_news_tag_list {
        margin-top: auto;

        .main_news_tag {
          font-size: 23.5px;
          font-weight: 500;
        }
      }
    }
  }

  .go_svg {
    width: 0.8rem;
    height: 0.8rem;
  }

  .go_svg_2 {
    width: 23.92px;
    height: 23.92px;
  }

  .go_news_svg {
    width: 15.45px;
    height: 15.45px;
  }
  .apple_svg {
    width: 28.97px;
    height: 35.63px;
  }

  .google_svg {
    width: 31.2px;
    height: 34.74px;
  }
  .elec_svg {
    width: 37.44px;
    height: 53.04px;
  }
  .wallet_svg {
    width: 20rem;
  }

  .sto_svg {
    width: 20rem;
    height: 16rem;
    margin: auto;
  }

  .trade_svg {
    width: 20rem;
    height: 16rem;
    margin: auto;
  }
}

@include mobile {
  .main_content {
    .content_box {
      .content_inner {
        .main_slider_controls {
          gap: 20px;
          .main_slide_btn_box {
            gap: 30px;
          }

          .main_slide_pagination {
            font-size: 18px;
          }
        }

        .main_item_box {
          gap: 15px;
          .item {
            aspect-ratio: 70 / 91;
            max-width: 300px;
            min-width: 300px;
            height: 100%;
            padding: 2rem 2rem;
            .item_title {
              font-size: 2em;
            }

            .item_sub_title {
              font-size: 0.8em;
            }

            .item_link {
              font-size: 1.1em;
              margin-top: auto;

              svg {
                height: auto;
              }
            }
          }
        }
      }
    }

    .app_box {
      .app_content_box {
        .app_title {
          font-size: 1.5em;
          line-height: 1.3;
        }

        .app_sub_title {
          font-size: 1.1em;
        }

        .app_btn_box {
          .app_btn {
            width: 100%;
            height: 60px;
            gap: 11.49px;

            .app_btn_text {
              font-size: 1.3em;
            }
          }
        }
      }
    }

    .goto_news_btn {
      font-size: 1.1em;
      width: 65%;
      height: 65px;
    }

    .overlay_container {
      .overlay_text {
        font-size: 2em;
      }
    }

    .last_content {
      .last_title {
        font-size: 2.2em;
        line-height: 1.3;
      }

      .last_sub_title {
        font-size: 1.2em;
      }

      .inquiry_btn {
        width: 45%;
        height: 4rem;
        font-size: 1.3em;
      }
    }

    .mobile_platform_button {
      height: 110px;
      border-radius: 10px;
    }

    .mobile_platform_title_box {
      padding: 20px 30px;
      gap: 26px;
    }

    .mobile_platform_title {
      font-size: 1.5em;
    }

    .mobile_platform_text {
      .mobile_platform_text_inner {
        padding: 30px;
      }

      .content_title {
        font-size: 1.2em;
      }

      .content_description_paragraph {
        font-size: 1em;
      }
      .content_description_list {
        font-size: 1em;
      }
    }

    .main_news_slider_wrapper {
      height: 450px;
    }

    .main_news_item {
      height: 25rem;
      grid-template-rows: 60% 40%;
    }

    .main_news_content {
      padding: 25px 20px;

      .main_news_date {
        font-size: 0.9em;
        margin-bottom: 20px;
      }

      .main_news_title {
        font-size: 1.4em;
        line-height: 35px;
        width: 90%;
      }

      .main_news_tag_list {
        margin-top: 20px;

        .main_news_tag {
          font-size: 1em;
        }
      }
    }
  }
  .main_news_progress_bar_wrapper {
    margin-top: 0;
    height: 4px;

    .progress_bar_indicator {
      height: 4px;
    }
  }
  .main_news_slide_btn {
    width: 4rem;
    height: 4rem;
    bottom: 22%;
  }

  .go_svg {
    width: 0.5rem;
    height: 0.5rem;
  }

  .go_svg_2 {
    width: 15px;
    height: 20px;
  }

  .go_news_svg {
    width: 0.6rem;
    height: 0.6rem;
  }
  .apple_svg {
    width: 25px;
    height: 25px;
  }

  .google_svg {
    width: 25px;
    height: 25px;
  }
  .elec_svg {
    width: 22px;
    height: 30px;
  }
  .wallet_svg {
    width: 12rem;
    margin-bottom: 2rem;
  }

  .sto_svg {
    width: 12rem;
    height: 9rem;
    margin: auto;
  }

  .trade_svg {
    width: 13rem;
    height: 10rem;
    margin: auto;
  }

  .platform_drop_svg {
    width: 20px;
    height: 20px;
  }
}
