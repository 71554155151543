$color-black: #222222;
$color-white: #ffffff;
$color-gray-light: #999999;
$color-gray-medium: #898c8c;
$color-gray-dark: #383a3a;
$color-gray-darker: #242424;
$color-gray-darkest: #090606;
$color-accent: #de5d25;
$color-hover: #e9ecef;
$color-border: #383a3a;
$color-input-background: #383a3a;
$color-input-border: #999999;
$color-checkbox: #050303;

$font-color-primary: $color-white;
$font-color-secondary: $color-black;
$font-color-sub: $color-gray-light;
$font-color-accent: $color-accent;

$background-color-primary: $color-gray-darker;
$background-color-secondary: $color-white;
$background-color-sub: $color-black;
$background-color-border: $color-border;

$font-size-xx-large: 3.5em;
$font-size-x-large: 3.438em;
$font-size-large: 1.875em;
$font-size-medium: 1.813em;
$font-size-regular: 1em;
$font-size-small: 0.9375em;
$font-size-x-small: 0.875em;
$font-size-xx-small: 0.75em;

$font-size-banner-title: 2.625em;
$font-size-banner-subtitle: 2.675em;
$font-size-large-title: 9.6em;
$font-size-small-title: 1.063em;
$font-size-medium-title: 1.875em;
$font-size-medium-large: 1.5em;
$font-size-history-subtitle: 1.688em;
$font-size-slogan-content: 1.563em;

$font-weight-extra-bold: 800;
$font-weight-bold: 700;
$font-weight-semibold: 600;
$font-weight-medium: 500;
$font-weight-regular: 400;

$border-radius-large: 1.375rem;
$border-radius-medium: 0.625rem;
$border-radius-small: 0.20625rem;
$border-radius-x-small: 0.1875rem;

$spacing-xx-large: 12.5rem;
$spacing-x-large: 8.5rem;
$spacing-large: 5.957rem;
$spacing-medium: 2.375rem;
$spacing-small: 1.875rem;
$spacing-x-small: 0.625rem;

$gap-large: 2.656rem;
$gap-medium: 0.938rem;
$gap-small: 0.65rem;

$border-width-thin: 0.0625rem;
$border-width-medium: 0.0833rem;
$border-width-thick: 0.1rem;

$line-height-loose: 2;
$line-height-normal: 1.5;
$line-height-tight: 1.2;

$transition-default: 0.3s ease;

$max-width-desktop: 1200.03px;
$max-width-header: 1713px;

$font-family-primary: "Pretendard", "Noto Sans", sans-serif;

$z-index-modal: 1000;
$z-index-overlay: 999;
$z-index-header: 9999;

$padding-section-top: 8.113rem;
$padding-history: 10.194rem;
$padding-talent2: 9.375rem 22.031rem 10.312rem 22.031rem;
$padding-slogan-top: 8.25rem 0 0 0;

$max-width-talent-image: 103%;

$animation-distance: 1.25rem;

$color-hover-light: lighten($color-gray-dark, 5%);
$color-hover-dark: darken($color-gray-light, 20%);

$breakpoint-mobile: 480px;
$breakpoint-tablet-to-mobile: 768px;
$breakpoint-tablet: 1024px;
$breakpoint-laptop: 1280px;
