.custom_select_container {
  position: relative;
  width: 100%;
  box-sizing: border-box;
  &:focus-within .custom_select_box {
    border: 2px solid #dfb51d;
    outline: none;
  }
  .custom_select_box {
    position: relative;
    display: flex;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    border: 1px solid #ffffff;
    border-radius: 0.5rem;
    background-color: #383a3a;
    padding: 0.9375rem;
    align-items: center;
    justify-content: space-between;
    color: #ffffff;
    @include tablet {
      font-size: 24px;
      font-weight: 500;
      padding: 24px 23px;
      height: 76px;
      max-width: 100%;
      background-color: transparent;
    }
    @include tabletToMobile {
      font-size: 24px;
      font-weight: 500;
      padding: 24px 23px;
      height: 76px;
      max-width: 100%;
      background-color: transparent;
    }
    @include mobile {
      padding: 18px 17px;
      font-size: 1em;
      height: 60px;
    }

    &:hover {
      background-color: #333737;
    }

    .selected_option {
      flex-grow: 1;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .arrow {
      flex-shrink: 0;
      width: 0.4375rem;
      height: 0.4375rem;
      border: solid #ffffff;
      margin-right: 0.03125rem;
      border-width: 0 0.00625rem 0.00625rem 0;
      transition: transform 0.3s ease;

      &.down {
        transform: rotate(45deg);
      }

      &.up {
        transform: rotate(225deg);
      }
    }
  }

  .options_list {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    max-height: 13rem;
    overflow-y: auto;
    background-color: #383a3a;
    border: 1px solid #ffffff;
    border-radius: 0.5rem;
    padding: 0.425rem;
    z-index: 1000;

    .option_item {
      padding: 0.625rem;
      color: #ffffff;
      cursor: pointer;

      &:hover {
        background-color: #555555;
        color: #ffffff;
      }
    }
  }

  .custom_input_field {
    position: absolute;
    top: 0;
    width: 80%;
    padding: 24px 23px;
    border: none;
    border-radius: 0.5rem;
    background-color: transparent;
    color: #ffffff;
    opacity: 1;
    font-size: 26px;
    padding: 24px 23px;
    @include tablet {
      font-size: 24px;
      padding: 20px 23px;
    }
    @include tabletToMobile {
      font-size: 24px;
      padding: 20px 23px;
    }
    @include mobile {
      font-size: 20px;
      padding: 16px 17px;
    }
    :focus-visible {
      border: none;
      outline: none;
    }
    &:focus {
      outline: none !important;
      box-shadow: none !important;
    }
  }
}

@keyframes modalOverlayFade {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes modalSpring {
  0% {
    opacity: 0;
    transform: scale(0.8);
  }
  50% {
    opacity: 1;
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
}

.custom_overlay {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10000;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  animation: modalOverlayFade 0.3s ease forwards;

  .custom_option_modal {
    animation: modalSpring 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275) forwards;

    .custom_option_modal_list {
      display: flex;
      flex-direction: column;
      border: 1px solid #fff;
      border-radius: 10px;
      background-color: #222222;
      .custom_option_modal_item {
        border-bottom: 1px solid #fff;
        padding: 26.88px 58.4px;
        font-size: 29.5px;
        font-weight: 500;
        @include mobile {
          font-size: 20px;
          padding: 26.88px 58.4px 26.88px 20px;
        }
        &:last-child {
          border-bottom: none;
        }
      }
    }
  }
}
