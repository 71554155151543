.wrapper {
  padding: 12.5rem 22.031rem;
  background-color: #222;
  color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;

  .title_box {
    width: 100%;
    display: flex;
    flex-direction: column;

    .back_link {
      margin-bottom: 2.625rem;
      color: #ffffff;
      font-weight: 600;
      font-size: 1.25rem;
      display: flex;
      align-items: center;
      gap: 1.875rem;
      text-decoration: none;

      span {
        &:first-child {
          color: #999999;
        }

        .arrow_svg {
          transform: rotate(270deg);
          width: 10px;
          height: 10px;

          path {
            fill: #ffffff;
          }
        }
      }
    }

    .news_title {
      font-size: 1.9375rem;
      font-weight: 800;
      margin-bottom: 2.575rem;
      line-height: 1.4;
    }

    .date_tool_box {
      padding-bottom: 0.96rem;
      border-bottom: 0.1668rem solid #383a3a;
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;

      .news_date {
        font-size: 0.875em;
        font-weight: 600;
        color: #999999;
        display: block;
      }

      .tool_box {
        display: flex;
        align-items: center;
        gap: 0.718rem;

        .tool_btn {
          background: none;
          border: none;
          padding: 0;
          cursor: pointer;

          .tool_icon {
            width: 1.25rem;
            height: 1.25rem;
            fill: #ffffff;
            transition: fill 0.3s ease;

            &:hover {
              fill: #1da1f2;
            }
          }
        }
      }
    }
  }

  .content_box {
    display: flex;
    flex-direction: column;
    width: 55rem;

    .summation {
      font-size: 1em;
      font-weight: 600;
      padding-left: 0.75rem;
      border-left: 0.1rem solid #ffffff;
      margin: 2.125rem 0;
    }

    .news_image {
      display: block;
      max-width: 100%;
      height: auto;
      margin: 0 auto 0.9375rem auto;
      border-radius: 1px;
    }

    .press {
      font-weight: 500;
      font-size: 0.9375em;
      text-align: center;
      margin-bottom: 3.1875rem;
      color: #999999;
    }

    .news_content {
      font-size: 1.0625rem;
      font-weight: 500;
      letter-spacing: 0.28px;
      line-height: 1.5;
      word-break: keep-all;
      margin-bottom: 1.25rem;
    }

    .go_to_news {
      font-weight: 600;
      font-size: 1.0625rem;
      text-decoration: underline;
      margin-bottom: 8.125rem;
      cursor: pointer;
      color: #ffffff;

      &:hover {
        text-decoration: none;
      }
    }

    .inquiry {
      color: #999999;
      font-weight: 600;
      font-size: 1.0625rem;
    }
  }
}

@include tablet {
  .wrapper {
    padding: 270.56px 26px;

    .title_box {
      .back_link {
        font-size: 27px;
        font-weight: 500;

        span {
          .arrow_svg {
            width: 13.64px;
            height: 13.64px;
          }
        }
      }

      .news_title {
        font-size: 35px;
        font-weight: 700;
      }

      .date_tool_box {
        padding-bottom: 28.91px;

        .news_date {
          font-size: 20px;
          font-weight: 600;
        }

        .tool_box {
          gap: 1rem;

          .tool_btn {
            .tool_icon {
              width: 24px;
              height: 24px;
            }
          }
        }
      }
    }

    .content_box {
      width: 100%;
      padding: 0;

      .press {
        font-size: 20px;
        font-weight: 500;
        letter-spacing: -0.4px;
        margin-bottom: 63.35px;
      }

      .news_content {
        font-size: 20px;
        font-weight: 500;
        letter-spacing: 0.6px;
        margin-bottom: 54.74px;
      }

      .go_to_news {
        font-size: 26px;
        font-weight: 600;
      }

      .inquiry {
        font-size: 26px;
        font-weight: 600;
      }
    }
  }
}

@include tabletToMobile {
  .wrapper {
    padding: 270.56px 26px;

    .title_box {
      .back_link {
        font-size: 27px;
        font-weight: 500;

        span {
          .arrow_svg {
            width: 13.64px;
            height: 13.64px;
          }
        }
      }

      .news_title {
        font-size: 35px;
        font-weight: 700;
      }

      .date_tool_box {
        padding-bottom: 28.91px;

        .news_date {
          font-size: 20px;
          font-weight: 600;
        }

        .tool_box {
          gap: 1rem;

          .tool_btn {
            .tool_icon {
              width: 24px;
              height: 24px;
            }
          }
        }
      }
    }

    .content_box {
      width: 100%;
      padding: 0;

      .press {
        font-size: 20px;
        font-weight: 500;
        letter-spacing: -0.4px;
        margin-bottom: 63.35px;
      }

      .news_content {
        font-size: 20px;
        font-weight: 500;
        letter-spacing: 0.6px;
        margin-bottom: 54.74px;
      }

      .go_to_news {
        font-size: 26px;
        font-weight: 600;
      }

      .inquiry {
        font-size: 26px;
        font-weight: 600;
      }
    }
  }
}

@include mobile {
  .wrapper {
    padding: 150px 26px;

    .title_box {
      .back_link {
        font-size: 1em;
        gap: 1rem;
      }

      .news_title {
        font-size: 1.8em;
        font-weight: 700;
      }

      .date_tool_box {
        padding-bottom: 20px;
        .news_date {
          font-size: 16px;
        }
      }
    }

    .content_box {
      .press {
        font-size: 1em;
      }

      .news_content {
        font-size: 1.1em;
        margin-bottom: 10px;
      }

      .go_to_news {
        font-size: 20px;
        margin-bottom: 4rem;
      }

      .inquiry {
        font-size: 20px;
      }
    }
  }
}
