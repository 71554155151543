.footer_wrapper {
  width: 100%;
  height: 38rem;
  background-color: #090606;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;

  .footer_inner {
    width: 1208px;
    height: 493.313px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto auto;

    .info_box {
      display: flex;
      flex-direction: column;
      justify-self: start;

      .business {
        font-weight: 700;
        font-size: 1.2em;
        margin-bottom: 1.675rem;
      }

      .logo {
        margin-bottom: 2.475rem;

        .logo_img {
          width: 7.426rem;
        }
      }

      .info {
        font-size: 0.8em;
        font-weight: 600;
        line-height: 2;
        margin-bottom: 1.063rem;
        letter-spacing: 0.05rem;

        p {
          margin: 0;
        }
      }

      .ir {
        font-weight: 600;
        font-size: 0.94em;
        letter-spacing: 0.05rem;
        margin-bottom: 4rem;

        .ir_link {
          color: #ffffff;
          text-decoration: none;
          transition: text-decoration 0.3s ease;

          &:hover {
            text-decoration: underline;
          }
        }

        .separator {
          margin: 0 1.25rem;
          color: #ffffff;
        }
      }

      .copyright {
        font-weight: 700;
        font-size: 0.9375em;
        letter-spacing: -0.04rem;
        margin-bottom: 1.6875rem;
      }

      .sns_box {
        display: flex;
        gap: 1.156rem;

        .sns_link {
          display: flex;
          align-items: center;
          justify-content: center;
        }

        .sns_icon {
          width: 2.5rem;
          height: 2.5rem;
          fill: #ffffff;
          transition: fill 0.3s ease;

          &.insta_icon:hover {
            fill: #e4405f;
          }

          &.blog_icon:hover {
            fill: #1da1f2;
          }
        }
      }
    }

    .map_box {
      justify-self: end;
      display: flex;
      flex-direction: column;
      color: #999999;
      font-size: 1em;
      font-weight: 600;

      .title {
        margin-bottom: 1.187rem;
      }

      .map {
        display: flex;
        gap: 2.5rem;
        margin-bottom: 3.6rem;

        .sitemap_list {
          list-style: none;
          padding: 0;
          margin: 0;
          display: flex;
          flex-direction: column;
          gap: 1.062rem;

          .category {
            color: #ffffff;
            font-weight: 700;

            a {
              color: #ffffff;
              text-decoration: none;
              transition: color 0.3s ease;

              &:hover {
                text-decoration: underline;
              }
            }
          }

          li {
            a {
              color: #999999;
              text-decoration: none;
              transition: color 0.3s ease;

              &:hover {
                color: #ffffff;
                text-decoration: underline;
              }
            }
          }
        }
      }

      .link_box {
        margin-top: 4rem;
        grid-column: 1 / -1;
        display: flex;
        justify-content: space-between;

        .custom_dropdown_container {
          position: relative;
          width: 10rem;

          .dropdown_button {
            width: 100%;
            padding: 0.5rem 0.75rem;
            font-size: 0.875em;
            border: none;
            border-radius: 0.25rem;
            background-color: #ffffff;
            color: #222222;
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: space-between;
            transition: background-color 0.3s ease;

            &:focus {
              outline: none;
            }

            .arrow {
              width: 0.5rem;
              height: 0.5rem;
              border: solid #222222;
              border-width: 0 0.125rem 0.125rem 0;
              display: inline-block;
              padding: 0.25rem;
              margin-top: 0.2rem;
              transform: rotate(225deg);
              transition: transform 0.3s ease;

              &.down {
                transform: rotate(225deg);
                margin-top: 0.2rem;
              }

              &.up {
                transform: rotate(45deg);
                margin-bottom: 0.2rem;
              }
            }

            &:hover {
              background-color: #ffffff; /* lighten(#ffffff, 5%) results in #ffffff */
            }
          }

          .dropdown_menu {
            position: absolute;
            top: -5.52rem;
            left: 0;
            width: 10rem;
            background-color: #ffffff;
            border: 0.063rem solid #999999;
            border-radius: 0.25rem;
            box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.2);
            z-index: 1000;
            font-size: 0.75em;
            font-weight: 600;
            padding: 0.6rem;

            .dropdown_option {
              display: block;
              width: 100%;
              padding: 0.6rem 0;
              background-color: transparent;
              border: none;
              text-align: left;
              cursor: pointer;
              color: #222222;
              transition: font-weight 0.3s ease;

              &:first-child {
                border-bottom: 0.075rem solid #999999;
              }

              &:hover {
                font-weight: 700;
              }
            }
          }
        }
      }
    }
  }
}

@include laptop {
  .footer_wrapper {
    .footer_inner {
      width: 1000px;
      .map_box {
        font-size: 0.9em;
        .map {
          gap: 2rem;
        }
      }
    }
  }
}

@include tablet {
  .footer_wrapper {
    height: 100%;
    background-color: #222222;

    .footer_inner {
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 100%;
      padding: 73.24px 37px 129.59px 37px;

      .info_box {
        .business {
          font-size: 30px;
          margin-bottom: 60px;
        }

        .logo {
          margin-bottom: 60px;

          .logo_img {
            width: 198.28px;
            height: 54.81px;
          }
        }

        .info {
          line-height: 2.6;
          margin-bottom: 60.65px;
          font-size: 18.5px;
          font-weight: 500;
          letter-spacing: -0.185px;
        }

        .ir {
          font-size: 24.5px;
          font-weight: 400;
        }

        .copyright {
          font-size: 23px;
          margin-bottom: 44.87px;
          font-weight: 400;
        }

        .sns_box {
          .sns_icon {
            width: 72.87px;
            height: 72.87px;
          }
        }
      }

      .map_box {
        .title {
          display: none;
        }

        .map {
          display: none;
        }

        .link_box {
          margin-top: 41.58px;

          .custom_dropdown_container {
            .dropdown_button {
              width: 280.5px;
              height: 58px;
              font-size: 21px;

              .arrow {
                margin-right: 0.5rem;
              }
            }

            .dropdown_menu {
              width: 280.5px;
              font-size: 21px;
              top: -8.52rem;
              font-weight: 400;

              .dropdown_option {
                padding: 1rem 0;
              }
            }
          }
        }
      }
    }
  }
}

@include tabletToMobile {
  .footer_wrapper {
    height: 100%;
    background-color: #222222;

    .footer_inner {
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 100%;
      padding: 73.24px 37px 129.59px 37px;

      .info_box {
        .business {
          font-size: 30px;
          margin-bottom: 60px;
        }

        .logo {
          margin-bottom: 60px;

          .logo_img {
            width: 198.28px;
            height: 54.81px;
          }
        }

        .info {
          line-height: 2.6;
          margin-bottom: 60.65px;
          font-size: 18.5px;
          font-weight: 500;
          letter-spacing: -0.185px;
        }

        .ir {
          font-size: 24.5px;
          font-weight: 400;
        }

        .copyright {
          font-size: 23px;
          margin-bottom: 44.87px;
          font-weight: 400;
        }

        .sns_box {
          .sns_icon {
            width: 72.87px;
            height: 72.87px;
          }
        }
      }

      .map_box {
        .title {
          display: none;
        }

        .map {
          display: none;
        }

        .link_box {
          margin-top: 41.58px;

          .custom_dropdown_container {
            .dropdown_button {
              width: 280.5px;
              height: 58px;
              font-size: 21px;

              .arrow {
                margin-right: 0.5rem;
              }
            }

            .dropdown_menu {
              width: 280.5px;
              font-size: 21px;
              top: -8.52rem;
              font-weight: 400;

              .dropdown_option {
                padding: 1rem 0;
              }
            }
          }
        }
      }
    }
  }
}

@include mobile {
  .footer_wrapper {
    .footer_inner {
      padding: 73.24px 37px 80px 37px;

      .info_box {
        .business {
          font-size: 20px;
          margin-bottom: 40px;
        }

        .logo {
          margin-bottom: 40px;

          .logo_img {
            width: 160px;
            height: 45px;
          }
        }

        .info {
          font-size: 0.8em;
          line-height: 2;
          margin-bottom: 3rem;
        }

        .ir {
          font-size: 1em;
          margin-bottom: 3rem;
        }

        .copyright {
          font-size: 0.8em;
          margin-bottom: 2rem;
        }

        .sns_box {
          .sns_icon {
            width: 50px;
            height: 50px;
          }
        }
      }

      .map_box {
        .link_box {
          margin-top: 3rem;

          .custom_dropdown_container {
            .dropdown_button {
              width: 180px;
              height: 48px;
              font-size: 1em;
            }
          }
        }
      }
    }
  }
}
