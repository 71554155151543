.news_content_wrapper {
  position: relative;
  overflow: hidden;
  background-color: #222222;

  .news_inner {
    width: 1200.03px;
    margin: 0 auto;

    .news_section {
      padding: 10.3125rem 0;
      background-color: #222222;

      .tab_box {
        font-weight: 700;
        margin-bottom: 3.689rem;
        color: #ffffff;

        .tabs {
          display: flex;
          gap: 4.25rem;

          .tab {
            background: none;
            border: none;
            color: #999999;
            font-size: 1.813em;
            font-weight: 600;
            cursor: pointer;
            padding: 0;
            border-bottom: 0.1rem solid transparent;
            transition: color 0.3s ease, border-bottom 0.3s ease;

            &.active {
              color: #ffffff;
              font-weight: 700;
              border-bottom: 0.1rem solid #ffffff;
            }

            &:hover {
              color: #ffffff;
            }
          }
        }
      }
      .no_news_message {
        color: #ffffff;
        width: 100%;
        display: flex;
        justify-content: center;
        font-weight: 800;
        font-size: 1.875em;
      }
      .news_list {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(23.125rem, 1fr));
        gap: 2.719rem;
        margin-bottom: 3.375rem;

        .news_item {
          .news_link {
            display: flex;
            flex-direction: column;
            text-decoration: none;
            color: inherit;

            .news_img {
              width: 100%;
              height: 15.625rem;
              object-fit: cover;
              border-radius: 1.375rem;
              margin-bottom: 1.35rem;
            }

            .content_box {
              display: flex;
              flex-direction: column;
              padding: 0 1.1875rem;

              .news_title {
                color: #ffffff;
                font-size: 1.063em;
                font-weight: 800;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 1;
                -webkit-box-orient: vertical;
                margin-bottom: 0.867rem;
              }

              .news_at {
                color: #999999;
                font-size: 0.75em;
                font-weight: 700;
              }
            }
          }
        }
      }

      .pagination_container {
        display: flex;
        justify-content: center;
        margin-top: 2.5rem;

        .ellipsis {
          color: #999999;
        }
        .page_button {
          background-color: transparent;
          border: none;
          color: #999999;
          font-weight: 700;
          cursor: pointer;
          text-decoration: none;
          transition: color 0.3s ease, text-decoration 0.3s ease;
          padding: 0 0.316rem;

          &.active {
            color: #ffffff;
            text-decoration: underline;
          }

          &:disabled {
            cursor: not-allowed;
          }
        }
      }
    }
  }
}

@include laptop {
  .news_content_wrapper {
    .news_inner {
      width: 1000px;
    }
  }
}

@include tablet {
  .news_content_wrapper {
    .news_inner {
      width: 100%;
      .news_section {
        .tab_box {
          margin-bottom: 102px;

          .tabs {
            justify-content: center;

            .tab {
              font-size: 40px;
              font-weight: 600;

              &.active {
                font-size: 40px;
                font-weight: 600;
              }
            }
          }
        }

        .news_list {
          grid-template-columns: 1fr 1fr;
          grid-auto-rows: auto;
          gap: 2.719rem;
          justify-content: center;
          padding: 0 26px;

          .news_item {
            .news_link {
              .news_img {
                height: 100%;
                aspect-ratio: 314 / 250;
              }

              .content_box {
                padding: 0;
                .news_title {
                  font-size: 25px;
                  font-style: normal;
                  font-weight: 600;
                  -webkit-line-clamp: 2;
                  line-height: 1.4;
                }

                .news_at {
                  font-size: 20px;
                  font-weight: 600;
                }
              }
            }
          }
        }

        .pagination_container {
          margin-top: 59.25px;
          .page_button {
            font-size: 23.5px;
            font-weight: 600;
          }
        }
      }
    }
  }
}

@include tabletToMobile {
  .news_content_wrapper {
    .news_inner {
      width: 100%;
      .news_section {
        .tab_box {
          margin-bottom: 102px;

          .tabs {
            justify-content: center;

            .tab {
              font-size: 40px;
              font-weight: 600;

              &.active {
                font-size: 40px;
                font-weight: 600;
              }
            }
          }
        }

        .news_list {
          grid-template-columns: 1fr 1fr;
          grid-auto-rows: auto;
          gap: 2.719rem;
          justify-content: center;
          padding: 0 26px;

          .news_item {
            .news_link {
              .news_img {
                height: 100%;
                aspect-ratio: 314 / 250;
              }

              .content_box {
                padding: 0;
                .news_title {
                  font-size: 25px;
                  font-style: normal;
                  font-weight: 600;
                  -webkit-line-clamp: 2;
                  line-height: 1.4;
                }

                .news_at {
                  font-size: 20px;
                  font-weight: 600;
                }
              }
            }
          }
        }

        .pagination_container {
          margin-top: 59.25px;
          .page_button {
            font-size: 23.5px;
            font-weight: 600;
          }
        }
      }
    }
  }
}

@include mobile {
  .news_content_wrapper {
    .news_inner {
      .news_section {
        padding: 120px 0;
        .tab_box {
          margin-bottom: 60px;

          .tabs {
            gap: 4rem;
            .tab {
              font-size: 30px;
              &.active {
                font-size: 30px;
              }
            }
          }
        }

        .news_list {
          grid-template-columns: 1fr;

          .news_item {
            .news_link {
              .news_img {
                border-radius: 20px;
              }

              .content_box {
                .news_title {
                  font-size: 1.1em;
                  margin-bottom: 0.4rem;
                }

                .news_at {
                  font-size: 0.9em;
                }
              }
            }
          }
        }

        .pagination_container {
          .page_button {
            font-size: 1.1em;
          }
        }
      }
    }
  }
}
