.solution_detail_wrapper {
  padding-top: 112px;
  background-color: #242424;
  color: #ffffff;

  .solution_container {
    padding: 11.574rem 0;
    width: 75.937rem;
    height: auto;
    margin-left: 22.031rem;

    .solution_detail_title {
      font-weight: 700;
      color: #ffffff;
      font-size: 3.438em;
      margin-bottom: 1.69rem;
    }

    .solution_detail_text {
      font-weight: 600;
      color: #ffffff;
      font-size: 2em;
      margin-bottom: 3.639rem;
    }

    .solution_list_container {
      .solution_content_box {
        display: flex;
        margin-bottom: 4.359rem;

        .solution_image_box {
          min-width: 20.913rem;
          min-height: 24.25rem;
          border-radius: 1.375rem;
          border: 2px solid #383a3a;
          margin-right: 3.493rem;
          overflow: hidden;

          .solution_image {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }

        .solution_item {
          display: flex;
          flex-direction: column;
          border-left: 1px solid #ffffff;
          padding: 1.134rem 3.493rem;

          .item_title_box {
            display: flex;
            align-items: flex-end;
          }

          .solution_item_title {
            font-weight: 700;
            color: #ffffff;
            font-size: 2em;
          }

          .sub_title {
            color: #ffffff;
            font-size: 1.063em;
            font-weight: 600;
            margin-left: 0.625rem;
          }

          .text_box {
            margin-top: 1.939rem;
            color: #ffffff;
            font-size: 1.063em;
            font-weight: 400;
            line-height: 1.8;
          }

          .navigate_button {
            font-weight: 700;
            font-size: 1.063em;
            background-color: #181919;
            border-radius: 0.20625rem;
            border: 0.0625rem solid #ffffff;
            color: #ffffff;
            width: 12.25rem;
            height: 2.749rem;
            margin-top: auto;
            cursor: pointer;
            overflow: hidden;
            transition: background-color 0.5s ease, color 0.5s ease,
              border-color 0.5s ease;

            &:hover {
              color: #222222;
              border-color: #383a3a;
              background-color: #ffffff;
            }
          }
        }
      }
    }

    .solution_carousel {
      position: relative;
      overflow: hidden;
      width: 100%;
    }

    .carousel_inner {
      display: flex;
      width: 100%;
    }

    .carousel_button {
      width: 74.8px;
      height: 74.8px;
      position: absolute;
      top: 14%;
      transform: translateY(-50%);
      background-color: #383a3a;
      border: none;
      color: #ffffff;
      cursor: pointer;
      z-index: 1;
      border-radius: 7px;
      font-size: 1.5rem;
      padding: 0.3rem;
    }

    .carousel_button.prev {
      left: 10px;
    }

    .carousel_button.next {
      right: 10px;
    }
  }
}

@include laptop {
  .solution_detail_wrapper {
    .solution_container {
      width: 1000px;
      height: auto;
      margin-left: 0;
      margin: 0 auto;

      .solution_list_container {
        .solution_content_box {
          .solution_image_box {
            margin-right: 1.5rem;
          }
          .solution_item {
            padding: 1.134rem 1.5rem;
            .text_box{
              line-height: 2;
            }
          }
        }
      }
    }
  }
}

@include tablet {
  .solution_detail_wrapper {
    .solution_container {
      width: 100%;
      margin-left: 0;
      padding: 0 84px 168px 84px;
      margin-top: 108px;

      .solution_detail_title {
        font-size: 42px;
        font-weight: 600;
        text-align: center;
      }

      .solution_detail_text {
        font-size: 27px;
        font-weight: 500;
        text-align: center;
      }

      .solution_list_container {
        min-width: 100%;
        box-sizing: border-box;
        .solution_content_box {
          flex-direction: column;
          align-items: center;
          .solution_image_box {
            min-width: 0;
            min-height: 0;
            max-width: 267.9px;
            max-height: 267.9px;
            margin-right: 0;
            margin-bottom: 2rem;
            border-radius: 10px;

            .solution_image {
              padding-bottom: 3rem;
            }
          }

          .solution_item {
            margin-top: 56.1px;
            padding: 0;
            border-left: none;
            text-align: center;
            .item_title_box {
              flex-direction: column;
              align-items: center;
            }

            .solution_item_title {
              font-size: 30px;
              font-weight: 600;
            }

            .sub_title {
              margin: 0.3rem 0 0 0;
              font-size: 24px;
              font-weight: 500;
              line-height: 50px;
            }

            .text_box {
              font-size: 23.5px;
              font-weight: 500;
              line-height: 45px;
            }
          }
        }
      }
      .carousel_button {
        top: 8rem;
      }
    }
  }
}

@include tabletToMobile {
  .solution_detail_wrapper {
    .solution_container {
      width: 100%;
      margin-left: 0;
      padding: 0 84px 168px 84px;
      margin-top: 108px;

      .solution_detail_title {
        font-size: 42px;
        font-weight: 600;
        text-align: center;
      }

      .solution_detail_text {
        font-size: 27px;
        font-weight: 500;
        text-align: center;
      }

      .solution_list_container {
        min-width: 100%;
        box-sizing: border-box;
        .solution_content_box {
          flex-direction: column;
          align-items: center;
          .solution_image_box {
            min-width: 0;
            min-height: 0;
            max-width: 267.9px;
            max-height: 267.9px;
            margin-right: 0;
            margin-bottom: 2rem;
            border-radius: 10px;

            .solution_image {
              padding-bottom: 3rem;
            }
          }

          .solution_item {
            margin-top: 56.1px;
            padding: 0;
            border-left: none;
            text-align: center;
            .item_title_box {
              flex-direction: column;
              align-items: center;
            }

            .solution_item_title {
              font-size: 30px;
              font-weight: 600;
            }

            .sub_title {
              margin: 0.3rem 0 0 0;
              font-size: 24px;
              font-weight: 500;
              line-height: 50px;
            }

            .text_box {
              font-size: 23.5px;
              font-weight: 500;
              line-height: 45px;
            }
          }
        }
      }
    }
  }
}

@include mobile {
  .solution_detail_wrapper {
    .solution_container {
      padding: 0 26px 140px 26px;
      margin-top: 10%;

      .solution_detail_title {
        font-size: 2em;
        margin-bottom: 0.7rem;
      }

      .solution_detail_text {
        font-size: 1.1em;
        line-height: 30px;
        margin-bottom: 3.5rem;
      }

      .solution_list_container {
        .solution_content_box {
          .solution_image_box {
            max-width: 200px;
            max-height: 200px;
          }

          .solution_item {
            .solution_item_title {
              font-size: 1.5em;
              line-height: 30px;
            }

            .sub_title {
              font-size: 1.3em;
              line-height: 40px;
            }

            .text_box {
              font-size: 1.1em;
              line-height: 30px;
            }
          }
        }
      }

      .carousel_button {
        width: 60px;
        height: 60px;
        top: 6.3rem;
      }

      .carousel_button.prev {
        left: 0px;
      }

      .carousel_button.next {
        right: 0px;
      }
    }
  }
}
