.news_content_wrapper {
  position: relative;
  overflow: hidden;
  background-color: #242424;

  .news_inner {
    width: 1200.03px;
    margin: 0 auto;

    .news_section {
      padding: 10.3125rem 0;
      background-color: #242424;

      .tab_box {
        font-weight: 700;
        margin-bottom: 3.689rem;
        color: #ffffff;

        .tabs {
          display: flex;
          gap: 4.25rem;

          .tab {
            background: none;
            border: none;
            color: #999999;
            font-size: 1.813em;
            font-weight: 600;
            cursor: pointer;
            padding: 0;
            border-bottom: 0.1rem solid transparent;
            transition: color 0.3s ease, border-bottom 0.3s ease;

            &.active {
              color: #ffffff;
              font-weight: 700;
              border-bottom: 0.1rem solid #ffffff;
            }

            &:hover {
              color: #ffffff;
            }
          }
        }
      }

      .news_list {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(23.125rem, 1fr));
        gap: 2.719rem;
        margin-bottom: 3.375rem;

        .news_item {
          .news_link {
            display: flex;
            flex-direction: column;
            text-decoration: none;
            color: inherit;

            .news_img {
              width: 100%;
              height: 15.625rem;
              object-fit: cover;
              border-radius: 1.375rem;
              margin-bottom: 1.35rem;
            }

            .content_box {
              display: flex;
              flex-direction: column;
              padding: 0 1.1875rem;

              .news_title {
                color: #ffffff;
                font-size: 1.063em;
                font-weight: 800;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 1;
                -webkit-box-orient: vertical;
                margin-bottom: 0.867rem;
              }

              .news_at {
                color: #999999;
                font-size: 0.75em;
                font-weight: 700;
              }
            }
          }

          &.skeleton {
            .news_link {
              .news_img {
                background-color: #383a3a;
              }

              .content_box {
                .news_title,
                .news_at {
                  background-color: #383a3a;
                  height: 1em;
                  margin-bottom: 0.5em;
                  border-radius: 0.20625rem;
                }

                .news_title {
                  width: 70%;
                }

                .news_at {
                  width: 50%;
                }
              }
            }
          }
        }
      }

      .pagination_container {
        display: flex;
        justify-content: center;
        margin-top: 2.5rem;

        .page_button {
          background-color: transparent;
          border: none;
          color: #999999;
          font-weight: 700;
          cursor: pointer;
          text-decoration: none;
          transition: color 0.3s ease, text-decoration 0.3s ease;
          padding: 0 0.316rem;

          &.active {
            color: #ffffff;
            text-decoration: underline;
          }

          &:disabled {
            cursor: not-allowed;
          }
        }

        .ellipsis {
          color: #999999;
        }
      }
    }
  }

  @include tabletToMobile {
    .news_inner {
      width: 100%;
    }

    .news_section {
      padding: 8rem 0;

      .tab_box {
        margin-bottom: 6rem;

        .tabs {
          gap: 3rem;

          .tab {
            font-size: 1.813em;
          }
        }
      }

      .news_list {
        grid-template-columns: repeat(auto-fill, minmax(20rem, 1fr));
        gap: 2rem;

        .news_item {
          .news_link {
            .news_img {
              height: 14rem;
            }

            .content_box {
              .news_title {
                font-size: 1.813em;
              }

              .news_at {
                font-size: 0.875em;
              }
            }
          }

          &.skeleton {
            .news_link {
              .content_box {
                .news_title {
                  width: 60%;
                }

                .news_at {
                  width: 40%;
                }
              }
            }
          }
        }
      }

      .pagination_container {
        margin-top: 2rem;

        .page_button {
          font-size: 0.9375em;
        }
      }
    }
  }

  @include mobile {
    .news_inner {
      .news_section {
        padding: 6rem 0;

        .tab_box {
          margin-bottom: 4rem;

          .tabs {
            gap: 2rem;

            .tab {
              font-size: 0.9375em;
            }
          }
        }

        .news_list {
          grid-template-columns: 1fr;
          gap: 1.5rem;

          .news_item {
            .news_link {
              .news_img {
                height: 12rem;
              }

              .content_box {
                .news_title {
                  font-size: 0.875em;
                }

                .news_at {
                  font-size: 0.75em;
                }
              }
            }

            &.skeleton {
              .news_link {
                .content_box {
                  .news_title {
                    width: 80%;
                  }

                  .news_at {
                    width: 60%;
                  }
                }
              }
            }
          }
        }

        .pagination_container {
          margin-top: 1.5rem;

          .page_button {
            font-size: 0.75em;
          }
        }
      }
    }
  }
}

@include tabletToMobile {
  .news_content_wrapper {
    .news_inner {
      width: 100%;
    }

    .news_section {
      padding: 8rem 0;

      .tab_box {
        margin-bottom: 6rem;

        .tabs {
          gap: 3rem;

          .tab {
            font-size: 1.813em;
          }
        }
      }

      .news_list {
        grid-template-columns: repeat(auto-fill, minmax(20rem, 1fr));
        gap: 2rem;

        .news_item {
          .news_link {
            .news_img {
              height: 14rem;
            }

            .content_box {
              .news_title {
                font-size: 1.813em;
              }

              .news_at {
                font-size: 0.875em;
              }
            }
          }

          &.skeleton {
            .news_link {
              .content_box {
                .news_title {
                  width: 60%;
                }

                .news_at {
                  width: 40%;
                }
              }
            }
          }
        }
      }

      .pagination_container {
        margin-top: 2rem;

        .page_button {
          font-size: 0.9375em;
        }
      }
    }
  }
}

@include mobile {
  .news_content_wrapper {
    .news_inner {
      .news_section {
        padding: 6rem 0;

        .tab_box {
          margin-bottom: 4rem;

          .tabs {
            gap: 2rem;

            .tab {
              font-size: 0.9375em;
            }
          }
        }

        .news_list {
          grid-template-columns: 1fr;
          gap: 1.5rem;

          .news_item {
            .news_link {
              .news_img {
                height: 12rem;
              }

              .content_box {
                .news_title {
                  font-size: 0.875em;
                }

                .news_at {
                  font-size: 0.75em;
                }
              }
            }

            &.skeleton {
              .news_link {
                .content_box {
                  .news_title {
                    width: 80%;
                  }

                  .news_at {
                    width: 60%;
                  }
                }
              }
            }
          }
        }

        .pagination_container {
          margin-top: 1.5rem;

          .page_button {
            font-size: 0.75em;
          }
        }
      }
    }
  }
}
