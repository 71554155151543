@keyframes slide_up {
  from {
    transform: translateY(100%);
  }
  to {
    transform: translateY(0);
  }
}

.main_banner {
  box-sizing: border-box;
  width: 100%;
  height: 100vh;
  color: #ffffff;
  position: sticky;
  top: 0;
  left: 0;
  display: flex;
  overflow: hidden;

  .bg_img {
    width: 110vw;
    max-width: 1900px;
    object-fit: contain;
    position: absolute;
    top: 55%;
    right: 0;
    transform: translateY(-50%);
    z-index: -1;
  }

  .content_box {
    box-sizing: border-box;
    padding: 0 9.938em;
    display: flex;
    flex-direction: column;
    margin: auto 0;

    .title {
      font-size: 9.6em;
      font-weight: 600;
      display: flex;
      flex-direction: column;

      .title_line {
        display: inline-block;

        .letter {
          display: inline-block;
          transform: translateY(101%);
          animation: slide_up 0.5s forwards cubic-bezier(0, 0.98, 0.99, 1);
        }

        &:last-of-type .letter {
          margin-top: -0.8rem;
        }
      }
    }

    .descript {
      font-size: 1.2em;
      font-weight: 700;
      line-height: 1.5;
      display: flex;
      flex-direction: column;
      gap: 1.5rem;
      margin-top: 6.45rem;

      .line {
        .descript_animated_line {
          display: inline-block;
          transform: translateY(100%);
          animation: slide_up 0.5s forwards cubic-bezier(0, 0.98, 0.99, 1);
        }
      }
    }

    .btn_box {
      display: flex;
      margin-top: 2.3rem;
      gap: 1.2rem;

      .btn {
        width: 12.25em;
        height: 3.688em;
        background-color: transparent;
        border: 1px solid #999999;
        border-radius: 0.20625em;
        font-size: 1.32em;
        font-weight: 600;
        color: #ffffff;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 0.469em;
        cursor: pointer;
        transform: translateY(102%);
        animation: slide_up 0.2s forwards cubic-bezier(0, 0.98, 0.99, 1);
        transition: background-color 0.2s, color 0.2s;

        &:hover {
          background-color: #ffffff;
          color: #222222;

          svg {
            fill: #222222;
          }
        }
      }
    }
  }

  .hidden_box {
    overflow: hidden;
  }
}

.inquiry_svg {
  width: 1.375em;
  height: 1.25em;
  fill: #fff;
  transition: fill 0.2s ease;
}

.down_svg {
  width: 1.375em;
  height: 1.5625em;
  fill: #fff;
  transition: fill 0.2s ease;
}

@include laptop {
  .main_banner {
    .content_box {
      padding: 0 5em;

      .title {
        font-size: 7em;
      }

      .descript {
        font-size: 1em;
        margin-top: 6.45rem;
      }

      .btn_box {
        display: flex;
        margin-top: 3rem;
        gap: 1.2rem;

        .btn {
          width: 13rem;
          height: 4rem;
          font-size: 1.2em;
        }
      }
    }
  }
}

@include tablet {
  .main_banner {
    background-color: #000;

    .bg_img {
      transform: none;
      width: 30rem;
      height: auto;
      object-fit: contain;
      top: 15rem;
      right: 0;
      left: auto;
    }

    .content_box {
      margin: 0;
      width: 100%;
      padding: 9.375em 3.344em 6.4375em 3.344em;

      .title {
        font-size: 7.84em;
      }

      .bottom_section {
        margin-top: auto;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
      }

      .descript {
        width: 100%;
        font-size: 1.66em;
        line-height: 2;
        font-weight: 500;
        gap: 3em;
      }

      .btn_box {
        margin-top: 5em;
        width: 100%;
        display: grid;
        grid-template-columns: 1fr 1fr;

        .btn {
          width: 100%;
          height: 3.5em;
          font-size: 1.5em;
        }
      }
    }
  }

  .inquiry_svg {
    width: 2rem;
  }

  .down_svg {
    width: 2rem;
  }
}

@include tabletToMobile {
  .main_banner {
    background-color: #000;

    .bg_img {
      width: 27rem;
      height: auto;
      object-fit: contain;
      top: 14rem;
      right: 0;
      left: auto;
    }

    .content_box {
      margin: 0;
      width: 100%;
      padding: 9.375em 3.344em 6.4375em 3.344em;

      .title {
        font-size: 7.84em;
      }

      .btn_box {
        .btn {
          height: 3.5em;
          font-size: 1.84em;
        }
      }
    }
  }

  .inquiry_svg {
    width: 2rem;
  }

  .down_svg {
    width: 2rem;
  }
}

@include mobile {
  .main_banner {
    .bg_img {
      width: 70%;
      height: auto;
      object-fit: contain;
      top: 24vh;
      right: 0;
    }

    .content_box {
      padding: 6.25em 1.625em 3.125em 1.625em;

      .title {
        font-size: 5em;
      }

      .descript {
        font-size: 0.88em;
        line-height: 1.7;
        gap: 1.875em;
        margin-top: auto;
      }

      .btn_box {
        margin-top: 2.5em;

        .btn {
          height: 3.2em;
          font-size: 1em;
        }
      }
    }
  }

  .inquiry_svg {
    width: 1.4em;
  }

  .down_svg {
    width: 1.4em;
  }
}
