@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(1.25rem);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.banner_wrapper {
  height: 31.25rem;
  background-color: #000;
  position: relative;
  overflow: hidden;
  margin-top: 112px;

  .banner_img {
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: right center;
  }

  .mobile_banner_img {
    position: absolute;
    width: 341.947px;
    height: 452.845px;
    top: 5rem;
    right: 0;
  }
  .mobile_service_banner_img {
    position: absolute;
    width: 471.741px;
    height: 542.054px;
    top: 50.53px;
    right: 0;
  }
  .mobile_contact_banner_img {
    position: absolute;
    width: 471.741px;
    height: 650px;
    top: -50px;
    right: 0;
  }

  .banner_content_box {
    width: 100%;
    display: flex;
    flex-direction: column;
    z-index: 1;
    padding: 206px 10.78% 0 10.78%;

    .nav_content {
      font-weight: 600;
      font-size: 0.875em;
      color: #999999;
      opacity: 0;
      transform: translateY(1.25rem);
      animation: fadeInUp 1s forwards;
    }

    .banner_title {
      font-weight: 600;
      font-size: 2.625em;
      color: #ffffff;
      margin-top: 1.451rem;
      opacity: 0;
      transform: translateY(1.25rem);
      animation: fadeInUp 1s forwards 0.1s;
    }

    .banner_content {
      font-weight: 600;
      font-size: 1em;
      line-height: 1.6;
      color: #ffffff;
      margin-top: 1.676rem;
      display: flex;
      flex-direction: column;

      .content_span {
        opacity: 0;
        transform: translateY(1.25rem);
        animation: fadeInUp 1s forwards;

        &.delay_06 {
          animation-delay: 0.2s;
        }

        &.delay_08 {
          animation-delay: 0.3s;
        }
      }
    }
  }
}

.select_container {
  position: relative;
  width: 100%;
  background-color: #383a3a;
  font-family: "Pretendard", "Noto Sans", sans-serif;

  .select_label {
    display: block;
    margin-bottom: 0.5rem;
    font-size: 0.9375em;
    font-weight: 600;
    color: #ffffff;

    .required {
      color: #de5d25;
      margin-left: 0.25rem;
    }
  }

  .select_box {
    position: relative;
    cursor: pointer;
    border: 0.0833rem solid #ffffff;
    background-color: #383a3a;
    padding: 0.9375rem;
    font-size: 0.9375em;
    display: flex;
    height: 2.6625rem;
    border-radius: 0.20625rem;
    justify-content: space-between;
    align-items: center;
    color: #ffffff;
    width: 300px;
    box-sizing: border-box;
    overflow: hidden;

    &:hover {
      background-color: #333737;
    }

    .selected_option {
      flex-grow: 1;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .arrow {
      width: 0.4375rem;
      height: 0.4375rem;
      border: solid #ffffff;
      margin-right: 0.03125rem;
      border-width: 0 0.00625rem 0.00625rem 0;
      transition: transform 0.3s ease;

      &.down {
        transform: rotate(45deg);
        margin-bottom: 0.021875rem;
      }

      &.up {
        transform: rotate(225deg);
        margin-top: 0.021875rem;
      }
    }
  }

  .options_list {
    position: absolute;
    top: 100%;
    max-height: 13rem;
    overflow-y: auto;
    left: 0;
    right: 0;
    background-color: #383a3a;
    padding: 0.425rem;
    margin-top: 0.46875rem;
    z-index: 1000;
    border: 0.0625rem solid #ffffff;
    border-radius: 0.1875rem;

    &::-webkit-scrollbar {
      width: 1.28125rem;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #999999;
      border: 0.4375rem solid #383a3a;
      border-radius: 0.75rem;
    }

    &::-webkit-scrollbar-track {
      background-color: #383a3a;
    }

    .option_item {
      padding: 0.625rem;
      color: #ffffff;
      cursor: pointer;
      border-radius: 0.1875rem;

      &:hover {
        background-color: #e9ecef;
        color: #222222;
      }

      &.active {
        background-color: #555555;
        color: #ffffff;
      }

      &.custom_input_option {
        font-weight: bold;
        background-color: #444;
        color: #fff;
        &:hover {
          background-color: #555555;
        }
      }
    }
  }

  .custom_input_field {
    width: 100%;
    margin-top: 0.5rem;
    padding: 0.625rem 0.9375rem;
    border: 0.0833rem solid #ffffff;
    border-radius: 0.20625rem;
    background-color: #383a3a;
    color: #ffffff;
    font-size: 0.9375em;

    &:focus {
      outline: none;
      border-color: #de5d25;
    }
  }
}

.select_input {
  width: 70%;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  background-color: transparent;
  font-size: 26px;
  padding: 24px 0 24px 23px;
  color: #fff;
  border: none;
}

@include laptop {
  .banner_wrapper {
    .banner_img {
      position: absolute;
      top: 0;
      right: -3rem;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}

@include tablet {
  .banner_wrapper {
    background-color: #000;
    height: 100%;
    width: 100%;

    .banner_img {
      object-fit: none;
      width: 100%;
      height: auto;
      right: 0;
      object-position: initial;
    }

    .mobile_banner_img {
      width: 450px;
      height: 620.04px;
      top: 4rem;
      right: 1rem;
    }
    .mobile_service_banner_img {
      width: 530px;
      height: 609px;
      top: -2rem;
      right: 0;
    }
    .mobile_contact_banner_img {
      width: 550px;
      height: 757.83px;
      top: -3.5rem;
      right: 0rem;
    }

    .banner_content_box {
      height: 70vh;
      padding: 30vh 26px 0 26px;

      .nav_content {
        font-size: 1.4em;
        font-weight: 600;
        letter-spacing: 0.705px;
      }

      .banner_title {
        font-size: 2.4em;
        font-weight: 600;
        margin-top: 52.11px;
      }

      .banner_content {
        font-size: 1.5em;
        font-weight: 600;
        line-height: 38px;
        width: 77%;
        white-space: pre-line;
        margin-top: 40.74px;
      }
    }
  }

  .select_container {
    .select_label {
      font-size: 28.5px;
      font-weight: 700;
    }

    .select_box {
      font-size: 26px;
      font-weight: 500;
      padding: 24px 23px;
      height: 100%;
      width: 100%;
    }

    .custom_input_field {
      font-size: 26px;
      padding: 24px 23px;
      border: 1px solid #fff;
      border-radius: 5px;
    }
  }
}

@include tabletToMobile {
  .banner_wrapper {
    background-color: #000;
    height: 100%;
    width: 100%;

    .banner_img {
      object-fit: none;
      width: 100%;
      height: auto;
      right: 0;
    }

    .mobile_banner_img {
      width: 450px;
      height: 620.04px;
      top: 4rem;
      right: 1rem;
    }
    .mobile_service_banner_img {
      width: 530px;
      height: 609px;
      top: -2rem;
      right: 0;
    }
    .mobile_contact_banner_img {
      width: 550px;
      height: 757.83px;
      top: -3.5rem;
      right: 0rem;
    }

    .banner_content_box {
      height: 70vh;
      padding: 30vh 26px 0 26px;

      .nav_content {
        font-size: 1.4em;
        font-weight: 600;
        letter-spacing: 0.705px;
      }

      .banner_title {
        font-size: 2.4em;
        font-weight: 600;
        margin-top: 52.11px;
      }

      .banner_content {
        font-size: 1.5em;
        font-weight: 600;
        line-height: 38px;
        width: 77%;
        white-space: pre-line;
        margin-top: 40.74px;
      }
    }
  }

  .select_container {
    .select_label {
      font-size: 28.5px;
      font-weight: 700;
    }

    .select_box {
      font-size: 26px;
      font-weight: 500;
      padding: 24px 23px;
      height: 100%;
      width: 100%;
    }

    .custom_input_field {
      font-size: 26px;
      padding: 24px 23px;
      border: 1px solid #fff;
      border-radius: 5px;
    }
  }
}

@include mobile {
  .banner_wrapper {
    margin-top: 5rem;

    .mobile_banner_img {
      width: 300px;
      height: 413.36px;
      top: 0rem;
      right: -1rem;
    }
    .mobile_service_banner_img {
      width: 380px;
      height: 436.64px;
      top: 3rem;
      right: -3rem;
    }
    .mobile_contact_banner_img {
      width: 300px;
      height: 413.36px;
      top: -1rem;
      right: 0rem;
    }

    .banner_content_box {
      height: 68vh;
      padding: 30vh 26px 0 26px;

      .nav_content {
        font-size: 0.9em;
      }

      .banner_title {
        font-size: 2em;
        margin-top: 30px;
      }

      .banner_content {
        width: 87%;
        font-size: 1em;
        margin-top: 25px;
        line-height: 30px;
      }
    }
  }
}
