.company_wrapper {
  width: 100%;
  height: 100%;
  background-color: #242424;
  overflow-x: hidden;
  section {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #ffffff;

    &.mission_section {
      padding: 8.113rem 0;

      .section_inner {
        width: 1200.03px;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        gap: 2.656rem;

        .mission_part {
          display: flex;
          flex-direction: column;
          align-items: center;

          &:first-child {
            gap: 0.65rem;
          }

          &:last-child {
            gap: 0.938rem;
          }

          .mission_title {
            color: #999999;
            font-weight: 600;
            font-size: 0.875em;
          }

          .mission_heading {
            font-weight: 700;
            font-size: 1.875em;
          }

          .mission_content {
            font-weight: 600;
            font-size: 1.5em;
            text-align: center;

            .bold {
              font-weight: 800;
            }
          }
        }
      }
    }

    &.vision_section {
      background-color: #ffffff;
      color: #222222;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      padding: 8.113rem 0;
      gap: 2.656rem;

      .section_inner {
        width: 1200.03px;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        gap: 2.656rem;

        .vision_part {
          display: flex;
          flex-direction: column;
          align-items: center;
          text-align: center;
          &:first-child {
            gap: 0.65rem;
          }

          &:last-child {
            gap: 0.938rem;
          }

          .vision_title {
            color: #999999;
            font-weight: 600;
            font-size: 0.875em;
          }

          .vision_heading {
            font-weight: 700;
            font-size: 1.875em;
          }

          .vision_content {
            font-weight: 800;
            font-size: 1.5em;
          }
        }
      }
    }

    &.slogan_section {
      padding-top: 8.113rem;

      .section_inner {
        width: 1200.03px;
        margin: 0 auto;
        display: flex;
        flex-direction: column;

        .slogan_part {
          display: flex;
          flex-direction: column;

          &:first-child {
            align-items: center;
          }

          .slogan_title {
            color: #999999;
            font-weight: 600;
            font-size: 0.938em;
            margin-bottom: 0.65rem;
          }

          .slogan_heading {
            font-weight: 700;
            font-size: 1.875em;
            margin-bottom: 2.656rem;
          }

          .slogan_content {
            font-weight: 600;
            font-size: 1.563em;
            margin-bottom: 5.111rem;
            text-align: center;

            .bold {
              font-weight: 700;
            }
          }

          .slogans {
            display: flex;
            justify-content: center;
            gap: 6.25rem;
            width: 100%;

            .slogan_item {
              flex: 1;
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              border: 0.167rem solid #383a3a;
              border-radius: 1.375rem;
              height: 13.125rem;
              text-align: center;

              .slogan_icon {
                width: 2.783rem;
                max-height: 2.783rem;
                display: flex;
                justify-content: center;
                align-items: center;
              }
              .slogan_box {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                .slogan_name {
                  margin-top: 1.418rem;
                  font-size: 1.125em;
                  font-weight: 700;
                }

                .slogan_description {
                  margin-top: 0.507rem;
                  font-size: 1em;
                  font-weight: 700;
                }
              }
            }
          }
        }
      }
    }

    &.history_section {
      padding: 10.194rem 0;

      .section_history_inner {
        width: 1200.03px;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        align-items: start;
        gap: 3.698rem;

        .history_title {
          font-weight: 600;
          font-size: 3.5em;
          color: #ffffff;
          letter-spacing: -1.333px;
        }

        .history_content_box {
          display: flex;

          .history_header {
            display: flex;
            flex-direction: column;
            width: 15rem;

            .history_subtitle {
              font-size: 1.688em;
              font-weight: 800;
              color: #ffffff;
            }
          }

          .history_year_selector {
            display: flex;
            flex-direction: column;
            gap: 2.75rem;
            width: 12.5rem;

            .history_year {
              width: 5.312rem;
              height: 2.25rem;
              font-weight: 700;
              font-size: 1.25em;
              color: #ffffff;
              background-color: #242424;
              display: flex;
              justify-content: center;
              align-items: center;
              cursor: pointer;
              border: none;
              border-radius: 0.188rem;
              transition: background-color 0.3s ease, color 0.3s ease;

              &.active {
                color: #242424;
                background-color: #ffffff;
              }
            }
          }

          .history_details {
            display: flex;
            flex-direction: column;
            width: 50rem;
            height: 100%;

            .history_year_title {
              font-weight: 700;
              font-size: 1.625em;
              color: #ffffff;
              letter-spacing: -1.333px;
              margin-bottom: 3.286rem;
            }

            .history_month {
              font-weight: 600;
              font-size: 0.875em;
              color: #999999;
              margin-top: 0.68rem;

              &:nth-of-type(2) {
                margin-top: 0;
              }
            }

            .history_description {
              font-weight: 500;
              font-size: 1.125em;
              color: #ffffff;
              line-height: 1.5;
              &.title {
                font-weight: 800;
                font-size: 1.375em;
              }
            }
            .show_more_button_box {
              width: 100%;
              display: flex;
              justify-content: center;
              margin-top: 11.28px;
              .show_more_button {
                width: 315px;
                height: 55.789px;
                background-color: #fff;
                color: #242424;
                font-size: 23.5px;
                font-weight: 500;
                border-radius: 3.3px;
                display: flex;
                justify-content: center;
                align-items: center;
                gap: 19px;

                .show_more_button_svg {
                  transform: rotate(0deg);
                  transition: transform 0.2s ease;
                }
                .show_more_button_svg_trans {
                  transform: rotate(180deg);
                }
              }
            }
          }
        }
      }
    }

    &.patent_section {
      width: 1200.03px;
      margin: 0 auto;
      padding: 0 0 10.194rem 0;
      .patent_inner {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 3.698rem;
        .patent_title {
          font-weight: 600;
          font-size: 3.5em;
          color: #ffffff;
          letter-spacing: -1.333px;
          text-align: start;
        }
        .patent_list {
          display: flex;
          gap: 1rem;
          .patent_item {
            width: 280px;
            height: 400px;
            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }
        }
      }
    }

    &.talent1_section {
      width: 100%;
      height: 37.25rem;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    &.talent2_section {
      display: flex;
      flex-direction: column;
      gap: 6.25rem;
      justify-content: center;
      width: 1200.03px;
      padding: 9.375rem 0 10.312rem 0;
      margin: 0 auto;

      .talent2_section_wrapper {
        width: 105%;

        .talent_image {
          img {
            width: 100%;
            height: auto;
            object-fit: cover;
          }
        }
      }
    }

    .bold {
      font-weight: 800;
    }
  }
}
.history_other_years {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.5s ease-in-out, opacity 0.5s ease-in-out;
  opacity: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  &.show_all {
    max-height: 2000px;
    opacity: 1;
  }
  .history_year_title {
    font-weight: 700;
    font-size: 1.625em;
    color: #ffffff;
    letter-spacing: -1.333px;
    margin-bottom: 3.286rem;
  }
}

@include laptop {
  .company_wrapper {
    section {
      &.mission_section {
        .section_inner {
          width: 1000px;

          .mission_part {
            .mission_title {
              font-size: 0.7em;
            }

            .mission_heading {
              font-size: 1.8em;
            }

            .mission_content {
              font-size: 1.2em;
            }
          }
        }
      }

      &.vision_section {
        .section_inner {
          width: 1000px;
          .vision_part {
            .vision_title {
              font-size: 0.7em;
            }

            .vision_heading {
              font-size: 1.8em;
            }

            .vision_content {
              font-size: 1.2em;
            }
          }
        }
      }

      &.slogan_section {
        .section_inner {
          width: 1000px;

          .slogan_part {
            .slogan_title {
              font-size: 0.7em;
            }

            .slogan_heading {
              font-size: 1.8em;
            }

            .slogan_content {
              font-size: 1.2em;
            }

            .slogans {
              display: flex;
              justify-content: center;
              gap: 3rem;
              width: 100%;

              .slogan_item {
                flex: 1;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                border: 0.167rem solid #383a3a;
                border-radius: 1.375rem;
                height: 13.125rem;
                text-align: center;

                .slogan_icon {
                  width: 2.783rem;
                  max-height: 2.783rem;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                }
                .slogan_box {
                  display: flex;
                  flex-direction: column;
                  justify-content: center;
                  align-items: center;
                  .slogan_name {
                    margin-top: 1.418rem;
                    font-size: 1.125em;
                    font-weight: 700;
                  }

                  .slogan_description {
                    margin-top: 0.507rem;
                    font-size: 1em;
                    font-weight: 700;
                  }
                }
              }
            }
          }
        }
      }

      &.history_section {
        .section_history_inner {
          width: 1000px;

          .history_title {
            font-size: 3.5em;
          }

          .history_content_box {
            .history_header {
              display: flex;
              flex-direction: column;
              width: 15rem;

              .history_subtitle {
                font-size: 1.688em;
                font-weight: 800;
                color: #ffffff;
              }
            }

            .history_year_selector {
              display: flex;
              flex-direction: column;
              gap: 2.75rem;
              width: 12.5rem;

              .history_year {
                width: 5.312rem;
                height: 2.25rem;
                font-weight: 700;
                font-size: 1.25em;
                color: #ffffff;
                background-color: #242424;
                display: flex;
                justify-content: center;
                align-items: center;
                cursor: pointer;
                border: none;
                border-radius: 0.188rem;
                transition: background-color 0.3s ease, color 0.3s ease;

                &.active {
                  color: #242424;
                  background-color: #ffffff;
                }
              }
            }

            .history_details {
              display: flex;
              flex-direction: column;
              width: 50rem;
              height: 100%;

              .history_year_title {
                font-weight: 700;
                font-size: 1.625em;
                color: #ffffff;
                letter-spacing: -1.333px;
                margin-bottom: 3.286rem;
              }

              .history_month {
                font-weight: 600;
                font-size: 0.875em;
                color: #999999;
                margin-top: 0.68rem;

                &:nth-of-type(2) {
                  margin-top: 0;
                }
              }

              .history_description {
                font-weight: 500;
                font-size: 1.125em;
                color: #ffffff;
                line-height: 1.5;
                &.title {
                  font-weight: 800;
                  font-size: 1.375em;
                }
              }
              .show_more_button_box {
                width: 100%;
                display: flex;
                justify-content: center;
                margin-top: 11.28px;
                .show_more_button {
                  width: 315px;
                  height: 55.789px;
                  background-color: #fff;
                  color: #242424;
                  font-size: 23.5px;
                  font-weight: 500;
                  border-radius: 3.3px;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  gap: 19px;

                  .show_more_button_svg {
                    transform: rotate(0deg);
                    transition: transform 0.2s ease;
                  }
                  .show_more_button_svg_trans {
                    transform: rotate(180deg);
                  }
                }
              }
            }
          }
        }
      }
      &.patent_section {
        padding: 10.194rem 0;
        width: 1000px;
      }
      &.talent1_section {
        width: 100%;
        height: 37.25rem;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      &.talent2_section {
        display: flex;
        flex-direction: column;
        gap: 6.25rem;
        justify-content: center;
        width: 1000px;
        padding: 9.375rem 0 10.312rem 0;
        margin: 0 auto;

        .talent2_section_wrapper {
          width: 105%;

          .talent_image {
            img {
              width: 100%;
              height: auto;
              object-fit: cover;
            }
          }
        }
      }

      .bold {
        font-weight: 800;
      }
    }
  }
  .history_other_years {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.5s ease-in-out, opacity 0.5s ease-in-out;
    opacity: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    &.show_all {
      max-height: 2000px;
      opacity: 1;
    }
    .history_year_title {
      font-weight: 700;
      font-size: 1.625em;
      color: #ffffff;
      letter-spacing: -1.333px;
      margin-bottom: 3.286rem;
    }
  }
}

@include tablet {
  .company_wrapper {
    section {
      &.mission_section {
        padding: 67.63px 26px 67px 26px;
        .section_inner {
          width: 100%;
          gap: 31.09px;
          .mission_part {
            &:last-child {
              gap: 0;
            }

            .mission_title {
              font-size: 15px;
              font-weight: 500;
            }

            .mission_heading {
              font-size: 38.5px;
              font-weight: 600;
            }

            .mission_content {
              font-size: 27px;
              font-weight: 500;
              line-height: 44px;
            }
          }
        }
      }

      &.vision_section {
        padding: 67.63px 26px 67px 26px;
        gap: 0px;
        .section_inner {
          width: 100%;
          gap: 31.09px;
          .vision_part {
            &:last-child {
              gap: 0;
            }

            .vision_title {
              font-size: 15px;
              font-weight: 500;
            }

            .vision_heading {
              font-size: 38.5px;
              font-weight: 600;
            }

            .vision_content {
              font-size: 27px;
              font-weight: 600;
              line-height: 44px;
            }
          }
        }
      }

      &.slogan_section {
        padding: 67.63px 26px 0 26px;
        .section_inner {
          width: 100%;
          .slogan_part {
            .slogan_title {
              font-size: 15px;
              font-weight: 600;
              margin-bottom: 9.28px;
            }

            .slogan_heading {
              font-size: 38.5px;
              font-weight: 600;
              margin-bottom: 30.28px;
            }

            .slogan_content {
              font-size: 27px;
              font-weight: 500;
              line-height: 44px;
              display: flex;
              flex-direction: column;
            }

            .slogans {
              flex-direction: column;
              gap: 12px;
              .slogan_item {
                flex-direction: row;
                justify-content: space-between;
                padding: 38.46px 20% 42.9px 20%;

                &:nth-child(1) .slogan_icon {
                  width: 63.412px;
                }

                &:nth-child(2) .slogan_icon {
                  width: 55.723px;
                }

                &:nth-child(3) .slogan_icon {
                  width: 41.742px;
                  margin-left: 6.9px;
                }

                &:nth-child(3) .slogan_box {
                  margin-right: -35px;
                }

                .slogan_box {
                  margin-left: auto;
                  .slogan_name {
                    font-size: 27px;
                    font-weight: 600;
                    margin-top: 0;
                  }

                  .slogan_description {
                    font-size: 23.466px;
                    font-weight: 600;
                  }
                }
              }
            }
          }
        }
      }

      &.history_section {
        padding: 86.18px 26px;
        .section_history_inner {
          width: 100%;
          gap: 72px;
          .history_title {
            font-size: 42px;
            font-weight: 700;
            text-align: center;
            width: 100%;
          }

          .history_content_box {
            width: 100%;
            flex: 1;

            .history_details {
              width: 100%;
              flex: 1;
              .history_year_title {
                font-size: 47px;
                font-weight: 600;
                margin-bottom: 44px;
                &:nth-child(n + 2) {
                  margin-top: 72px;
                }
              }

              .history_month {
                font-size: 20px;
                font-weight: 600;
                line-height: 20px;
                margin: 15px 0 15px 0;

                &:nth-of-type(2) {
                  font-size: 22px;
                  margin: 15px 0 15px 0;
                }
              }

              .history_description {
                font-size: 22px;
                font-weight: 500;
                line-height: 37px;
                &.title {
                  font-size: 22px;
                  font-weight: 600;
                  line-height: 37px;
                }
              }
            }
          }
        }
      }

      &.patent_section {
        padding: 86.18px 26px;
        width: 100%;
        .patent_inner {
          .patent_title {
            font-size: 42px;
            font-weight: 700;
            text-align: center;
            width: 100%;
          }
        }
      }

      &.talent1_section {
        max-height: 418.6px;
      }

      &.talent2_section {
        padding: 70px 23px;
        width: 100%;
        .talent2_section_wrapper {
          width: 100%;
          overflow-x: auto;
          overflow-y: hidden;
          padding-bottom: 12%;

          &::-webkit-scrollbar {
            height: 8px;
          }

          &::-webkit-scrollbar-track {
            background-color: #444444;
          }

          &::-webkit-scrollbar-thumb {
            background-color: #fff;
            border-radius: 0;
            width: 50%;
          }

          .talent_image {
            padding: 0 10px;
            width: 100%;

            img {
              width: auto;
              height: 372px;
            }
          }
        }
      }
    }
  }
  .history_other_years {
    .history_year_title {
      font-size: 47px;
      font-weight: 600;
      margin-bottom: 44px;
      margin-top: 72px;
    }
  }
}

@include tabletToMobile {
  .company_wrapper {
    section {
      &.mission_section {
        padding: 67.63px 26px 67px 26px;
        .section_inner {
          width: 100%;
          gap: 31.09px;
          .mission_part {
            &:last-child {
              gap: 0;
            }

            .mission_title {
              font-size: 15px;
              font-weight: 500;
            }

            .mission_heading {
              font-size: 38.5px;
              font-weight: 600;
            }

            .mission_content {
              font-size: 27px;
              font-weight: 500;
              line-height: 44px;
            }
          }
        }
      }

      &.vision_section {
        padding: 67.63px 26px 67px 26px;
        gap: 0px;
        .section_inner {
          width: 100%;
          gap: 31.09px;
          .vision_part {
            &:last-child {
              gap: 0;
            }

            .vision_title {
              font-size: 15px;
              font-weight: 500;
            }

            .vision_heading {
              font-size: 38.5px;
              font-weight: 600;
            }

            .vision_content {
              font-size: 27px;
              font-weight: 600;
              line-height: 44px;
            }
          }
        }
      }

      &.slogan_section {
        padding: 67.63px 26px 0 26px;
        .section_inner {
          width: 100%;
          .slogan_part {
            .slogan_title {
              font-size: 15px;
              font-weight: 600;
              margin-bottom: 9.28px;
            }

            .slogan_heading {
              font-size: 38.5px;
              font-weight: 600;
              margin-bottom: 30.28px;
            }

            .slogan_content {
              font-size: 27px;
              font-weight: 500;
              line-height: 44px;
              display: flex;
              flex-direction: column;
            }

            .slogans {
              flex-direction: column;
              gap: 12px;
              .slogan_item {
                flex-direction: row;
                justify-content: space-between;
                padding: 38.46px 20% 42.9px 20%;

                &:nth-child(1) .slogan_icon {
                  width: 63.412px;
                }

                &:nth-child(2) .slogan_icon {
                  width: 55.723px;
                }

                &:nth-child(3) .slogan_icon {
                  width: 41.742px;
                  margin-left: 6.9px;
                }

                &:nth-child(3) .slogan_box {
                  margin-right: -35px;
                }

                .slogan_icon {
                }
                .slogan_box {
                  .slogan_name {
                    font-size: 27px;
                    font-weight: 600;
                    margin-top: 0;
                  }

                  .slogan_description {
                    font-size: 23.466px;
                    font-weight: 600;
                  }
                }
              }
            }
          }
        }
      }

      &.history_section {
        padding: 86.18px 26px;
        .section_history_inner {
          width: 100%;
          gap: 72px;
          .history_title {
            font-size: 42px;
            font-weight: 700;
            text-align: center;
            width: 100%;
          }

          .history_content_box {
            width: 100%;
            flex: 1;

            .history_details {
              width: 100%;
              flex: 1;
              .history_year_title {
                font-size: 47px;
                font-weight: 600;
                margin-bottom: 44px;
                &:nth-child(n + 2) {
                  margin-top: 72px;
                }
              }

              .history_month {
                font-size: 20px;
                font-weight: 600;
                line-height: 20px;
                margin: 15px 0 15px 0;

                &:nth-of-type(2) {
                  font-size: 22px;
                  margin: 15px 0 15px 0;
                }
              }

              .history_description {
                font-size: 22px;
                font-weight: 500;
                line-height: 37px;
                &.title {
                  font-size: 22px;
                  font-weight: 600;
                  line-height: 37px;
                }
              }
            }
          }
        }
      }
      &.patent_section {
        padding: 86.18px 26px;
        width: 100%;
  
      }

      &.talent1_section {
        max-height: 418.6px;
      }

      &.talent2_section {
        padding: 70px 23px;
        width: 100%;
        .talent2_section_wrapper {
          width: 100%;
          overflow-x: auto;
          overflow-y: hidden;
          padding-bottom: 12%;

          &::-webkit-scrollbar {
            height: 8px;
          }

          &::-webkit-scrollbar-track {
            background-color: #444444;
          }

          &::-webkit-scrollbar-thumb {
            background-color: #fff;
            border-radius: 0;
            width: 50%;
          }

          .talent_image {
            padding: 0 10px;
            width: 100%;

            img {
              width: auto;
              height: 372px;
            }
          }
        }
      }
    }
  }
  .history_other_years {
    .history_year_title {
      font-size: 47px;
      font-weight: 600;
      margin-bottom: 44px;
      margin-top: 72px;
    }
  }
}

@include mobile {
  .company_wrapper {
    section {
      &.mission_section {
        .section_inner {
          .mission_part {
            .mission_title {
              font-size: 0.7rem;
            }

            .mission_heading {
              font-size: 1.9em;
            }

            .mission_content {
              font-size: 1em;
              line-height: 30px;
            }
          }
        }
      }

      &.vision_section {
        .section_inner {
          .vision_part {
            .vision_title {
              font-size: 0.7rem;
            }

            .vision_heading {
              font-size: 1.9em;
            }

            .vision_content {
              font-size: 1em;
              line-height: 25px;
            }
          }
        }
      }

      &.slogan_section {
        .section_inner {
          .slogan_part {
            .slogan_title {
              font-size: 0.7rem;
            }

            .slogan_heading {
              font-size: 1.9em;
            }

            .slogan_content {
              font-size: 1em;
              line-height: 30px;
              margin-bottom: 3.5rem;
            }

            .slogans {
              .slogan_item {
                padding: 26px 20% 26px 15%;
                justify-content: start;

                &:nth-child(2) {
                  padding: 26px 18% 26px 15%;
                }

                &:nth-child(1) .slogan_icon {
                  width: 9vw;
                }

                &:nth-child(2) .slogan_icon {
                  width: 9vw;
                }

                &:nth-child(3) .slogan_icon {
                  width: 7vw;
                  margin-left: 6.9px;
                }

                &:nth-child(3) .slogan_box {
                  margin-right: -35px;
                }

                .slogan_box {
                  .slogan_name {
                    font-size: 1.1em;
                  }

                  .slogan_description {
                    font-size: 1.1em;
                  }
                }
              }
            }
          }
        }
      }

      &.history_section {
        .section_history_inner {
          .history_content_box {
            .history_details {
              .history_year_title {
                font-size: 35px;
                margin-bottom: 30px;
              }

              .history_month {
                font-size: 1em;
                font-weight: 600;
                line-height: 20px;
                margin: 15px 0 15px 0;

                &:nth-of-type(2) {
                  font-size: 1.1em;
                  margin: 10px 0 10px 0;
                }
              }

              .history_description {
                font-size: 1.2em;
                font-weight: 500;
                line-height: 30px;
                &.title {
                  font-size: 1.3em;
                  font-weight: 600;
                  line-height: 30px;
                }
              }
              .show_more_button_box {
                .show_more_button {
                  width: 280px;
                  font-size: 1.2em;
                }
              }
            }
          }
        }
      }
    }
  }
  .history_other_years {
    .history_year_title {
      font-size: 30px;
    }
  }
  
}
