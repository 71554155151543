.news_banner_wrapper {
  margin-top: 112px;
}

.news_banner_skeleton_slide {
  flex: 0 0 100%;
  padding: 12.938rem;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.news_banner_skeleton_slide .news_banner_skeleton_image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: transparent;
  z-index: -1;
}

.news_banner_skeleton_slide .news_banner_skeleton_text {
  background-color: #383a3a;
  border-radius: 4px;
  margin-bottom: 1.5rem;
}

.news_banner_skeleton_slide .news_banner_skeleton_text.category {
  width: 10rem;
  height: 1rem;
}

.news_banner_skeleton_slide .news_banner_skeleton_text.title {
  width: 30rem;
  height: 2.5rem;
}

.news_banner_skeleton_slide .news_banner_skeleton_text.content {
  width: 40rem;
  height: 1.5rem;
}

.news_banner_skeleton_slide .news_banner_skeleton_button {
  width: 12.25rem;
  height: 2.975rem;
  background-color: #383a3a;
  border-radius: 0.20625rem;
  animation: skeleton-loading 1.5s infinite linear;
}

@keyframes skeleton-loading {
  0% {
    background-color: #383a3a;
  }
  50% {
    background-color: #4f4f4f;
  }
  100% {
    background-color: #383a3a;
  }
}

@include tabletToMobile {
  .news_banner_wrapper {
    margin-top: 0;
  }

  .news_banner_skeleton_slide {
    padding: 30vh 26px 0 26px;
    justify-content: flex-start;
  }

  .news_banner_skeleton_slide .news_banner_skeleton_text.category {
    width: 10rem;
    height: 1rem;
  }

  .news_banner_skeleton_slide .news_banner_skeleton_text.title {
    width: 25rem;
    height: 2.5rem;
  }

  .news_banner_skeleton_slide .news_banner_skeleton_text.content {
    width: 30rem;
    height: 1.5rem;
  }

  .news_banner_skeleton_slide .news_banner_skeleton_button {
    width: 315px;
    height: 60px;
  }
}

@include mobile {
  .news_banner_skeleton_slide {
    padding: 30vh 16px 0 16px;
  }

  .news_banner_skeleton_slide .news_banner_skeleton_text.category {
    width: 8rem;
    height: 1rem;
  }

  .news_banner_skeleton_slide .news_banner_skeleton_text.title {
    width: 20rem;
    height: 2.5rem;
  }

  .news_banner_skeleton_slide .news_banner_skeleton_text.content {
    width: 25rem;
    height: 1.5rem;
  }

  .news_banner_skeleton_slide .news_banner_skeleton_button {
    width: 230px;
    height: 50px;
  }
}
