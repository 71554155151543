.contact_wrapper {
  width: 100%;
  height: 100%;
  background-color: #242424;
  padding: 9.351rem 0 12.512rem 0;

  .contact_inner {
    width: 1200.03px;
    margin: 0 auto;

    .contact_box {
      display: flex;
      flex-direction: column;
      justify-content: start;
      align-items: center;
      background-color: #242424;
      color: #ffffff;

      .contact_title {
        width: 100%;
        font-weight: 600;
        font-size: 3.438em;
        margin-bottom: 1.65rem;
        letter-spacing: -0.2083rem;
      }

      .contact_subtitle {
        font-weight: 600;
        font-size: 1.813em;
        width: 100%;
        margin-bottom: 3.611rem;
      }

      .contact_grid_container {
        width: 100%;
        display: flex;
        gap: 3.125rem;
        margin-bottom: 10.312rem;

        .contact_grid {
          width: 10rem;
          height: 17.0625rem;
          display: grid;
          grid-template-columns: 1fr 1.5fr;
          gap: 0;
          flex: 1;

          .grid_item {
            display: flex;
            align-items: start;
            padding: 1.125rem 1.25rem;
            border-top: 0.0833rem solid #ffffff;
            font-weight: 600;
            font-size: 1.063em;
            white-space: nowrap;
            color: #ffffff;
            transition: background-color 0.3s ease, color 0.3s ease;

            &.label {
              font-weight: 700;
              color: #ffffff;
              height: 100%;
              &_address {
                font-weight: 700;
                align-items: center;
              }
            }

            &.content {
              font-weight: 600;
              color: #ffffff;
            }

            &.traffic_content {
              padding: 1.125rem 0;
              padding-left: 1.25rem;
              line-height: 1.5625rem;
              font-weight: 600;
            }
          }

          .grid_item:nth-child(1),
          .grid_item:nth-child(2) {
            border-top: 2px solid #ffffff;
          }

          .grid_item:nth-child(9),
          .grid_item:nth-child(10) {
            border-bottom: 2px solid #ffffff;
          }
        }

        .contact_map {
          width: 100%;
          max-width: 32.576rem;
          height: 20.0625rem;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          overflow: hidden;
        }
      }
    }

    .bold {
      font-weight: 800;
    }
  }
}

@include laptop {
  .contact_wrapper {
    .contact_inner {
      width: 1000px;
      .contact_box {
        .contact_title {
          font-size: 3em;
        }
        .contact_subtitle {
          font-size: 1.6em;
        }
      }
    }
  }
}

@include tablet {
  .contact_wrapper {
    padding: 97.47px 0 0 0;
    .contact_inner {
      width: 100%;
      padding: 0 26px;
      .contact_box {
        .contact_title {
          font-size: 42px;
          font-weight: 600;
          margin-bottom: 18.76px;
          text-align: center;
        }

        .contact_subtitle {
          font-size: 27px;
          font-weight: 600;
          margin-bottom: 92.16px;
          text-align: center;
        }

        .contact_grid_container {
          flex-direction: column;

          .contact_grid {
            width: 100%;

            .grid_item {
              padding: 34px 0;
              font-size: 24px;

              &.content {
                font-weight: 500;
              }

              &.traffic_content {
                font-weight: 500;
                padding: 34px 0;
                line-height: 1.4;
                white-space: pre-wrap;
                word-break: keep-all;
              }
              &:first-child {
                align-items: start;
              }
            }
          }

          .contact_map {
            max-width: 100%;
            height: 100%;
            aspect-ratio: 1 / 1;
          }
        }
      }
    }
  }
}

@include tabletToMobile {
  .contact_wrapper {
    padding: 97.47px 0 0 0;
    .contact_inner {
      width: 100%;
      padding: 0 26px;
      .contact_box {
        .contact_title {
          font-size: 42px;
          font-weight: 600;
          margin-bottom: 18.76px;
          text-align: center;
        }

        .contact_subtitle {
          font-size: 27px;
          font-weight: 600;
          margin-bottom: 92.16px;
          text-align: center;
        }

        .contact_grid_container {
          flex-direction: column;

          .contact_grid {
            width: 100%;

            .grid_item {
              padding: 34px 0;
              font-size: 24px;

              &.content {
                font-weight: 500;
              }

              &.traffic_content {
                font-weight: 500;
                padding: 34px 0;
                line-height: 1.4;
                white-space: pre-wrap;
                word-break: keep-all;
              }
            }
          }

          .contact_map {
            max-width: 100%;
            height: 100%;
            aspect-ratio: 1 / 1;
          }
        }
      }
    }
  }
}

@include mobile {
  .contact_wrapper {
    .contact_inner {
      .contact_box {
        .contact_subtitle {
          font-size: 1em;
        }

        .contact_grid_container {
          .contact_grid {
            grid-template-columns: 1fr;
            .grid_item {
              padding: 15px 0;
              font-size: 1.1em;

              &.content {
                padding: 0 0 1.125rem 0;
              }

              &.traffic_content {
                padding: 0 0 15px 0;
              }
            }

            .grid_item:nth-child(9) {
              border-bottom: none;
            }

            .grid_item:nth-child(even) {
              &:last-child {
                border-bottom: 2px solid #ffffff;
              }
              border: none;
            }
          }

          .contact_map {
            max-width: 100%;
            height: 100%;
            aspect-ratio: 2 / 1;
          }
        }
      }
    }
  }
}
