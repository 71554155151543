.contactform_form_wrapper {
  background-color: #242424;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .contactform_form_box {
    width: 100%;

    .contactform_title_box {
      display: flex;
      flex-direction: column;
      @include tablet {
        text-align: center;
      }
      @include tabletToMobile {
        text-align: center;
      }

      .contactform_title {
        font-weight: 600;
        letter-spacing: -3.333px;
        font-size: 3.5em;
        @include tablet {
          font-size: 42px;
          font-weight: 700;
          text-align: center;
        }
        @include tabletToMobile {
          font-size: 42px;
          font-weight: 700;
          text-align: center;
        }
      }

      .contactform_sub_title {
        margin-top: 1.4125rem;
        margin-bottom: 3.375rem;
        font-weight: 600;
        font-size: 1.875em;
        @include tablet {
          font-size: 27px;
          font-weight: 500;
          text-align: center;
        }
        @include tabletToMobile {
          font-size: 27px;
          font-weight: 500;
          text-align: center;
        }

        @include mobile {
          font-size: 1em;
        }
      }
    }

    form {
      .contactform_form_inner {
        width: 100%;
        display: flex;
        margin-bottom: 2.125rem;
        @include tablet {
          flex-direction: column;
          margin-bottom: 0;
        }
        @include tabletToMobile {
          flex-direction: column;
          margin-bottom: 0;
        }

        &.contactform_form_inner_textarea {
          margin-bottom: 2.125rem;
        }

        .contactform_label_box {
          min-width: 9.625rem;
          height: 2.6625rem;
          display: flex;
          align-items: center;
          padding-top: 0;
          white-space: pre-wrap;

          &.contactform_label_box_textarea {
            padding-top: 20px;
          }

          .contactform_label {
            display: block;
            font-weight: 700;
            margin-bottom: 0.5rem;
            @include tablet {
              font-size: 24px;
              font-weight: 600;
              margin-bottom: 24.36px;
            }
            @include tabletToMobile {
              font-size: 24px;
              font-weight: 600;
              margin-bottom: 24.36px;
            }

            @include mobile {
              font-size: 1.1em;
              margin-bottom: 20px;
            }

            .contactform_label_span {
              color: #de5d25;
            }
          }
        }

        .contactform_input_box {
          width: 65.8125rem;
          height: 2.6625rem;
          border-bottom: none;
          @include tablet {
            margin-bottom: 45.12px;
            height: 100%;
            width: 100%;
          }
          @include tabletToMobile {
            margin-bottom: 45.12px;
            height: 100%;
            width: 100%;
          }
          @include mobile {
            margin-bottom: 30px;
          }

          &.contactform_input_box_border {
            border-bottom: 0.1rem solid #ffffff;
            @include tablet {
              border-bottom: none;
            }
            @include tabletToMobile {
              border-bottom: none;
            }
          }

          .contactform_input {
            width: 100%;
            background-color: #242424;
            color: #ffffff;
            font-size: 0.9375em;
            height: 100%;
            padding: 0.625rem 0.9375rem;
            border: none;
            @include tablet {
              font-size: 26px;
              border: 1px solid #fff;
              border-radius: 0.5rem;
              padding: 24px 23px;
            }
            @include tabletToMobile {
              font-size: 26px;
              border: 1px solid #fff;
              border-radius: 0.5rem;
              padding: 24px 23px;
            }
            @include mobile {
              font-size: 1.1em;
              padding: 18px 17px;
            }
          }
        }

        .contactform_email_input_box {
          width: 100%;
          display: flex;
          align-items: center;
          @include tablet {
            flex-direction: column;
            margin-bottom: 45.12px;
            width: 100%;
          }
          @include tabletToMobile {
            flex-direction: column;
            margin-bottom: 45.12px;
            width: 100%;
          }
          @include mobile {
            margin-bottom: 30px;
          }
          .email_input_container {
            display: flex;
            align-items: center;
            width: 100%;
            @include tablet {
              flex-direction: row;
              .contactform_email_input {
                height: 76px;
                flex: 1;
                max-width: 45%;
              }

              .custom_select_container {
                flex: 1;
                max-width: 55%;
                min-width: 150px;
              }
            }
            @include tabletToMobile {
              flex-direction: row;
              .contactform_email_input {
                height: 76px;
                flex: 1;
                max-width: 45%;
              }

              .custom_select_container {
                flex: 1;
                max-width: 55%;
                min-width: 150px;
              }
            }
            @include mobile {
              .contactform_email_input {
                font-size: 1.1em;
                height: 60px;
                flex: 1;
                max-width: 45%;
              }

              .custom_select_container {
                flex: 1;
                max-width: 55%;
                min-width: 150px;
              }
            }
          }

          .contactform_email_input {
            flex: 1;
            background-color: #242424;
            padding: 0.625rem 0.9375rem;
            border: none;
            font-size: 0.9375em;
            border-bottom: 0.0625rem solid #ffffff;
            color: #ffffff;

            @include tablet {
              flex: 1;
              font-size: 26px;
              border: 1px solid #fff;
              border-radius: 0.5rem;
              padding: 24px 23px;
            }
            @include tabletToMobile {
              flex: 1;
              font-size: 26px;
              border: 1px solid #fff;
              border-radius: 0.5rem;
              padding: 24px 23px;
            }
            @include mobile {
              font-size: 1.1em;
              padding: 16px 17px;
            }
          }

          .contactform_email_domain_sign_box {
            width: 2.4375rem;
            font-weight: 700;
            font-size: 1.75em;
            display: flex;
            justify-content: center;
            align-items: center;
            color: #ffffff;

            @include tabletToMobile {
              font-size: 28.465px;
            }
          }

          .contactform_email_span {
            font-size: 1.3rem;
            margin: 0 0.5rem;
            @include tablet {
              margin: 0 19.29px;
              font-size: 28.465px;
              font-weight: 600;
            }
            @include tabletToMobile {
              margin: 0 19.29px;
              font-size: 28.465px;
              font-weight: 600;
            }
            @include mobile {
              margin: 0 6px;
              font-size: 1.4em;
            }
          }

          .contactform_email_input_inner {
            display: flex;
            gap: 1.875rem;
            flex: 1;
            @include tablet {
              flex-direction: column;
              gap: 0.625rem;
              width: 100%;
            }
            @include tabletToMobile {
              flex-direction: column;
              gap: 0.625rem;
              width: 100%;
            }

            .contactform_email_input_current {
              flex: 1;
              font-size: 0.9375em;
              border: 0.0833rem solid #ffffff;
              border-radius: 0.5rem;
              background-color: #383a3a;
              padding-left: 0.9375rem;
              color: #ffffff;

              &:disabled {
                background-color: #383a3a;
              }
              @include tablet {
                width: 100%;
                font-size: 26px;
                border: 1px solid #fff;
                border-radius: 0.5rem;
                padding: 24px 23px;
              }
              @include tabletToMobile {
                width: 100%;
                font-size: 26px;
                border: 1px solid #fff;
                border-radius: 0.5rem;
                padding: 24px 23px;
              }
            }

            .contactform_custom_select_box {
              flex: 1;
              font-size: 0.9375em;
              @include tablet {
                width: 100%;
                font-size: 26px;
                border: 1px solid #fff;
                border-radius: 0.5rem;
                padding: 24px 23px;
              }
              @include tabletToMobile {
                width: 100%;
                font-size: 26px;
                border: 1px solid #fff;
                border-radius: 0.5rem;
                padding: 24px 23px;
              }
            }
          }
        }

        .contactform_text_area_inner {
          display: flex;
          text-align: right;
          width: 65.8125rem;
          flex-direction: column;
          @include tablet {
            width: 100%;
          }
          @include tabletToMobile {
            width: 100%;
          }

          .contactform_text_area {
            width: 100%;
            height: 9.375rem;
            padding: 1rem;
            font-size: 0.9375em;
            font-weight: 600;
            color: #ffffff;
            border: 0.0833rem solid #999999;
            border-radius: 0.20625rem;
            background-color: #383a3a;
            resize: none;
            @include tablet {
              width: 100%;
              height: 360px;
              font-size: 26px;
              font-weight: 500;
              margin-top: 10px;
              border: 1px solid #fff;
              border-radius: 0.5rem;
              padding: 24px 23px;
            }
            @include tabletToMobile {
              width: 100%;
              height: 360px;
              font-size: 26px;
              font-weight: 500;
              margin-top: 10px;
              border: 1px solid #fff;
              border-radius: 0.5rem;
              padding: 24px 23px;
            }
            @include mobile {
              font-size: 1.3em;
              padding: 16px 17px;
            }
          }

          .contactform_char_count {
            font-size: 0.8125em;
            margin-top: 0.75rem;
            margin-bottom: 0.75rem;
            font-weight: 600;
            color: #ffffff;
            @include tablet {
              font-size: 22px;
              font-weight: 600;
            }
            @include tabletToMobile {
              font-size: 22px;
              font-weight: 600;
            }
            @include mobile {
              font-size: 18px;
            }
          }
        }
      }

      .contactform_checkbox_wrapper {
        display: block;
        width: 100%;
        border-radius: 1.375rem;
        background-color: #383a3a;
        padding: 2.375rem 7.188rem;
        @include tablet {
          background-color: transparent;
          padding: 0;
          margin: 3rem 0 0 0;
        }
        @include tabletToMobile {
          background-color: transparent;
          padding: 0;
          margin: 3rem 0 0 0;
        }

        .contactform_privacy_policy_content {
          font-weight: 600;
          font-size: 0.9375em;
          line-height: 2;

          &.contactform_privacy_policy_content_subtitle {
            font-weight: 700;
            font-size: 0.875em;
            @include tablet {
              color: #f7f7f7;
              font-size: 23.5px;
              font-weight: 700;
              letter-spacing: -0.705px;
              margin-bottom: 3px;
            }
            @include tabletToMobile {
              color: #f7f7f7;
              font-size: 23.5px;
              font-weight: 700;
              letter-spacing: -0.705px;
              margin-bottom: 3px;
            }
          }

          &.contactform_privacy_policy_content_content_title {
            font-weight: 600;
            font-size: 0.9375em;
            margin-bottom: 1.03125rem;
            @include tablet {
              color: #f9f9fa;
              font-size: 23.5px;
              font-weight: 500;
              letter-spacing: -0.235px;
              line-height: 1.5;
            }
            @include tabletToMobile {
              color: #f9f9fa;
              font-size: 23.5px;
              font-weight: 500;
              letter-spacing: -0.235px;
              line-height: 1.5;
            }
          }

          &.contactform_privacy_policy_content_warning {
            font-weight: 600;
            font-size: 0.9375em;
            margin-top: 1.03125rem;
            @include tablet {
              font-size: 23.5px;
              font-weight: 500;
              letter-spacing: -0.235px;
              line-height: 1.5;
            }
            @include tabletToMobile {
              font-size: 23.5px;
              font-weight: 500;
              letter-spacing: -0.235px;
              line-height: 1.5;
            }
          }
        }

        .contactform_checkbox_box {
          display: flex;
          align-items: center;
          margin-bottom: 2.375rem;
          position: relative;
          @include tablet {
            margin-bottom: 0;
          }
          @include tabletToMobile {
            margin-bottom: 0;
          }

          .contactform_checkbox {
            display: none;
          }

          .contactform_custom_checkbox {
            width: 1.1875rem;
            height: 1.1875rem;
            border: 0.0833rem solid #ffffff;
            border-radius: 0.625rem;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: 0.53125rem;
            background-color: #383a3a;
            position: relative;
            cursor: pointer;
            @include tablet {
              width: 31.756px;
              height: 31.8px;
              border-radius: 50%;
            }
            @include tabletToMobile {
              width: 31.756px;
              height: 31.8px;
              border-radius: 50%;
            }

            &.contactform_checked {
              background-color: #ffffff;

              &::after {
                content: "";
                display: block;
                width: 0.28125rem;
                height: 0.59375rem;
                border: 0.3125rem solid #050303;
                border-width: 0 0.125rem 0.125rem 0;
                transform: rotate(45deg);
                position: absolute;
                left: 0.3125rem;
                top: 0.125rem;
                @include tablet {
                  width: 0.48125rem;
                  height: 0.89375rem;
                  border-width: 0 0.225rem 0.225rem 0;
                  left: 0.6125rem;
                  top: 0.325rem;
                }
                @include tabletToMobile {
                  width: 0.48125rem;
                  height: 0.89375rem;
                  border-width: 0 0.225rem 0.225rem 0;
                  left: 0.6125rem;
                  top: 0.325rem;
                }
              }
            }
          }

          .contactform_checkbox_label {
            display: block;
            font-weight: 600;
            font-size: 0.9375em;
            cursor: pointer;
            color: #ffffff;
            @include tablet {
              font-size: 23.5px;
              font-weight: 500;
              letter-spacing: -0.94px;
            }
            @include tabletToMobile {
              font-size: 23.5px;
              font-weight: 500;
              letter-spacing: -0.94px;
            }
          }

          .contactform_view_details_button {
            margin-left: 1rem;
            background: none;
            border: none;
            color: #fff;
            cursor: pointer;
            font-size: 1em;
            text-decoration: underline;
            @include tablet {
              font-size: 23.5px;
              font-style: normal;
              font-weight: 500;
              margin-left: auto;
            }
            @include tabletToMobile {
              font-size: 23.5px;
              font-style: normal;
              font-weight: 500;
              margin-left: auto;
            }

            &:hover {
              color: #ff8c42;
            }
          }
        }

        .contactform_privacy_policy_grid {
          border: 0.0833rem solid #ffffff;
          width: 100%;
          height: 7rem;
          display: grid;
          grid-template-columns: repeat(3, 1fr);
          grid-template-rows: 1fr 2fr;
          margin: 1.181rem 0;
          @include tablet {
            border: 1px solid #ffffff;
          }
          @include tabletToMobile {
            border: 1px solid #ffffff;
          }

          .contactform_privacy_policy_grid_item {
            text-align: center;
            display: flex;
            align-items: center;
            justify-content: center;
            border: 0.0833rem solid #ffffff;
            padding: 0.75rem;

            &.contactform_collect_purpose,
            &.contactform_collect_term,
            &.contactform_collect_item_content,
            &.contactform_collect_purpose_content,
            &.contactform_collect_term_content {
              height: auto;
            }

            &.contactform_collect_item_content,
            &.contactform_collect_purpose_content,
            &.contactform_collect_term_content {
              line-height: 1.6;
            }

            &.contactform_collect_term_content {
              font-size: 28.5px;
              font-weight: 700;
            }
            @include tablet {
              padding: 0.75rem;
              font-size: 23.5px;
              font-weight: 500;
              letter-spacing: -1.645px;
              border: 1px solid #ffffff;
            }
            @include tabletToMobile {
              padding: 0.75rem;
              font-size: 23.5px;
              font-weight: 500;
              letter-spacing: -1.645px;
              border: 1px solid #ffffff;
            }
          }
        }
      }
    }

    .contactform_bold {
      font-weight: 800;
    }

    // 모달 스타일링
    .modal_overlay {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.5);
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 10000;
    }

    .modal_content {
      background: #242424;
      color: #ffffff;
      padding: 1.5rem;
      width: 100%;
      max-width: 800px; // 모달의 최대 너비 설정
      position: relative;

      .contactform_checkbox_box {
        display: flex;
        align-items: center;
        margin-bottom: 1rem;
        position: relative;
        padding: 15px 0 30px 0;
        border-bottom: 1px solid #fff;

        .contactform_checkbox {
          display: none;
        }

        .contactform_custom_checkbox {
          width: 31.756px;
          height: 31.8px;
          border: 0.0833rem solid #ffffff;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-right: 0.53125rem;
          background-color: #383a3a;
          position: relative;
          cursor: pointer;

          &.contactform_checked {
            background-color: #ffffff;

            &::after {
              content: "";
              display: block;
              width: 0.48125rem;
              height: 0.89375rem;
              border: 0.2125rem solid #050303;
              border-width: 0 0.225rem 0.225rem 0;
              transform: rotate(45deg);
              position: absolute;
              left: 0.6125rem;
              top: 0.325rem;
            }
          }
        }

        .contactform_checkbox_label {
          display: block;
          font-size: 23.5px;
          font-weight: 500;
          cursor: pointer;
          color: #ffffff;
        }

        .contactform_view_details_button {
          margin-left: auto;
          background: none;
          border: none;
          color: #fff;
          cursor: pointer;
          font-size: 23.5px;
          font-weight: 500;
          text-decoration: underline;

          &:hover {
            color: #ff8c42;
          }
        }
      }

      .contactform_privacy_policy_content {
        font-weight: 600;
        font-size: 0.9375em;
        line-height: 2;

        &.contactform_privacy_policy_content_subtitle {
          font-weight: 700;
          font-size: 0.875em;
          @include tablet {
            color: #f7f7f7;
            font-size: 23.5px;
            font-weight: 700;
            letter-spacing: -0.705px;
            margin-bottom: 3px;
          }
          @include tabletToMobile {
            color: #f7f7f7;
            font-size: 23.5px;
            font-weight: 700;
            letter-spacing: -0.705px;
            margin-bottom: 3px;
          }
        }

        &.contactform_privacy_policy_content_content_title {
          font-weight: 600;
          font-size: 0.9375em;
          margin-bottom: 1.03125rem;

          @include tabletToMobile {
            color: #f9f9fa;
            font-size: 23.5px;
            font-weight: 500;
            letter-spacing: -0.235px;
            line-height: 1.5;
          }
        }

        &.contactform_privacy_policy_content_warning {
          font-weight: 600;
          font-size: 0.9375em;
          margin-top: 1.03125rem;
          @include tablet {
            font-size: 23.5px;
            font-weight: 500;
            letter-spacing: -0.235px;
            line-height: 1.5;
          }
          @include tabletToMobile {
            font-size: 23.5px;
            font-weight: 500;
            letter-spacing: -0.235px;
            line-height: 1.5;
          }
        }
      }

      .contactform_privacy_policy_grid {
        border: 0.0833rem solid #ffffff;
        width: 100%;
        height: 7rem;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: 1fr 2fr;
        margin: 1.181rem 0;
        @include tablet {
          border: 1px solid #ffffff;
        }
        @include tabletToMobile {
          border: 1px solid #ffffff;
        }

        .contactform_privacy_policy_grid_item {
          text-align: center;
          display: flex;
          align-items: center;
          justify-content: center;
          border: 0.0833rem solid #ffffff;
          padding: 0.75rem;

          &.contactform_collect_purpose,
          &.contactform_collect_term,
          &.contactform_collect_item_content,
          &.contactform_collect_purpose_content,
          &.contactform_collect_term_content {
            height: auto;
          }

          &.contactform_collect_item_content,
          &.contactform_collect_purpose_content,
          &.contactform_collect_term_content {
            line-height: 1.6;
          }

          &.contactform_collect_term_content {
            font-size: 28.5px;
            font-weight: 700;
          }
          @include tablet {
            padding: 0.75rem;
            font-size: 23.5px;
            font-weight: 500;
            letter-spacing: -1.645px;
            border: 1px solid #ffffff;
          }
          @include tabletToMobile {
            padding: 0.75rem;
            font-size: 23.5px;
            font-weight: 500;
            letter-spacing: -1.645px;
            border: 1px solid #ffffff;
          }
        }
      }

      .contactform_button_box {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        .contactform_submit_button {
          margin-top: 4.375rem;
          color: #ffffff;
          background-color: #242424;
          border: 0.0833rem solid #ffffff;
          border-radius: 0.20625rem;
          cursor: pointer;
          font-weight: 600;
          font-size: 1.063em;
          transition: 0.3s ease;
          padding: 0.938rem 4.289rem;

          &:hover {
            border: 0.0833rem solid #383a3a;
            background-color: #ffffff;
            color: #222222;
          }
          @include tablet {
            font-size: 28.5px;
            font-weight: 600;
            margin-top: 0;
            padding: 23.16px 0;
          }
          @include tabletToMobile {
            font-size: 28.5px;
            font-weight: 600;
            margin-top: 0;
            padding: 23.16px 0;
          }
        }
      }
    }

    .contactform_bold {
      font-weight: 800;
    }
  }
}
.contactform_button_box {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 62.5px 0 15px 0;

  .contactform_submit_button {
    color: #ffffff;
    background-color: #242424;
    border: 0.05rem solid #ffffff;
    border-radius: 0.5rem;
    cursor: pointer;
    font-weight: 600;
    font-size: 1.063em;
    transition: 0.3s ease;
    padding: 0.938rem 4.289rem;
    width: 100%;
    @include tablet {
      flex: 1;
      font-size: 28.5px;
      font-weight: 600;
      margin-top: 0;
      padding: 23.16px 0;
    }
    @include tabletToMobile {
      flex: 1;
      font-size: 28.5px;
      font-weight: 600;
      margin-top: 0;
      padding: 23.16px 0;
    }
    @include mobile {
      font-size: 18px;
      padding: 16px 0;
    }

    &:hover {
      border: 0.05rem solid #383a3a;
      background-color: #ffffff;
      color: #242424;
    }
  }
}
