@mixin laptop {
  @media (max-width: #{$breakpoint-laptop}) {
    @content;
  }
}
@mixin tablet {
  @media (max-width: #{$breakpoint-tablet}) {
    @content;
  }
}
@mixin tabletToMobile {
  @media (max-width: #{$breakpoint-tablet-to-mobile}) {
    @content;
  }
}
@mixin mobile {
  @media (max-width: #{$breakpoint-mobile}) {
    @content;
  }
}
