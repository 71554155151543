@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(1.25rem);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.news_banner_wrapper {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 43.75rem;
  margin: 0 auto;
  margin-top: 112px;

  .news_slides_container {
    display: flex;
    height: 100%;
    transition: transform 0.5s;

    .news_slide_item {
      flex: 0 0 100%;
      padding: 12.938rem;
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      color: #ffffff;

      .news_slide_img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        z-index: -1;
      }

      .news_slide_category {
        font-weight: 600;
        font-size: 0.875em;
        color: #999999;
        margin-bottom: 1.451rem;
        opacity: 0;
        transform: translateY(1.25rem);
        animation: fadeInUp 1s forwards;
      }

      .news_slide_title {
        font-weight: 600;
        font-size: 2.675em;
        white-space: pre-wrap;
        line-height: 1.3;
        word-break: keep-all;
        margin-bottom: 1.676rem;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        opacity: 0;
        transform: translateY(1.25rem);
        animation: fadeInUp 1s forwards 0.1s;
      }

      .news_slide_content {
        font-weight: 600;
        font-size: 1em;
        line-height: 1.5;
        white-space: pre-wrap;
        word-break: keep-all;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        margin-bottom: 4.375rem;
      }

      .news_go_news_btn {
        font-size: 1.063em;
        font-weight: 600;
        width: 12.25rem;
        height: 2.975rem;
        border: 0.0625rem solid #ffffff;
        border-radius: 0.20625rem;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #ffffff;
        text-decoration: none;
        cursor: pointer;
        transition: all 0.3s ease;

        .news_arrow_icon {
          width: 1rem;
          height: 1rem;
          margin-left: 0.5rem;
        }

        &:hover {
          border: 0.0625rem solid #242424;
          background-color: #ffffff;
          color: #222222;

          .news_arrow_icon {
            fill: #222222;
          }
        }
      }
    }
  }

  .news_slide_btn {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background: none;
    color: #ffffff;
    border: none;
    padding: 0.625rem;
    cursor: pointer;
    font-size: 1.125em;
    border-radius: 50%;
    z-index: 2;

    &.news_prev_slide_btn {
      left: 3.125rem;

      .news_prev_arrow {
        width: 2.5rem;
        height: 2.5rem;
        transform: rotate(270deg);
        opacity: 0.2;
      }
    }

    &.news_next_slide_btn {
      right: 3.125rem;

      .news_next_arrow {
        width: 3rem;
        height: 3rem;
        transform: rotate(90deg);
        opacity: 0.2;
      }
    }

    &:hover {
      opacity: 1;
    }
  }

  .news_pagination_dots {
    display: flex;
    gap: 1rem;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 3.312rem;
    list-style: none;

    .news_pagination_dot {
      width: 12rem;
      height: 0.25rem;
      background-color: #666666;
      border-radius: 20rem;
      opacity: 0.5;
      cursor: pointer;
      position: relative;
      transition: opacity 0.3s ease;

      &::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        background-color: #ffffff;
        transform-origin: left;
        transform: scaleX(0);
        transition: none;
      }

      &.active {
        opacity: 1;

        &::after {
          transform: scaleX(1);
          transition: transform 5s linear;
        }
      }

      &:not(.active)::after {
        transform: scaleX(0);
        transition: none;
      }
    }
  }
  .news_progress_bar_wrapper {
    width: 100%;
    display: flex;
    gap: 1rem;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 3.312rem;
    list-style: none;
    padding: 0 26px;

    .news_progress_bar_background {
      width: 100%;
      height: 8px;
      background-color: #666666;
      cursor: pointer;
      position: relative;
      transition: opacity 0.3s ease;
    }
    .news_progress_bar_indicator {
      position: absolute;
      top: 50%;
      left: 0%;
      width: 35%;
      height: 8px;
      background-color: #ffffff;
      transform: translateY(-50%);
      transition: left 0.3s ease;
    }
  }
}

@include tablet {
  .news_banner_wrapper {
    margin-top: 0;
    height: 100vh;

    .news_slides_container {
      display: flex;
      .news_slide_item {
        padding: 30vh 26px 0 26px;
        justify-content: start;

        .news_slide_category {
          font-size: 1.4em;
          font-weight: 600;
          letter-spacing: 0.705px;
          margin-bottom: 52.1px;
        }

        .news_slide_title {
          font-size: 2.4em;
          font-weight: 600;
          margin-bottom: 40.42px;
          -webkit-line-clamp: 2;
        }

        .news_slide_content {
          font-size: 1.5em;
          font-weight: 500;
          -webkit-line-clamp: 3;
        }

        .news_go_news_btn {
          font-size: 23.5px;
          font-weight: 600;
          width: 315px;
          height: 60px;
        }
      }
    }
  }
}

@include tabletToMobile {
  .news_banner_wrapper {
    margin-top: 0;
    height: 100vh;

    .news_slides_container {
      display: flex;
      .news_slide_item {
        padding: 30vh 26px 0 26px;
        justify-content: start;

        .news_slide_category {
          font-size: 1.4em;
          font-weight: 600;
          letter-spacing: 0.705px;
          margin-bottom: 52.1px;
        }

        .news_slide_title {
          font-size: 2.4em;
          font-weight: 600;
          margin-bottom: 40.42px;
          -webkit-line-clamp: 2;
        }

        .news_slide_content {
          font-size: 1.5em;
          font-weight: 500;
          -webkit-line-clamp: 3;
        }

        .news_go_news_btn {
          font-size: 23.5px;
          font-weight: 600;
          width: 315px;
          height: 60px;
        }
      }
    }
  }
}

@include mobile {
  .news_banner_wrapper {
    .news_slides_container {
      .news_slide_item {
        padding: 30vh 26px 0 26px;

        .news_slide_category {
          font-size: 0.9em;
          margin-bottom: 30px;
        }

        .news_slide_title {
          -webkit-line-clamp: 2;
          font-size: 1.8em;
          margin-bottom: 30px;
        }

        .news_slide_content {
          font-size: 1em;
          margin-bottom: 2.5rem;
        }

        .news_go_news_btn {
          font-size: 1em;
          font-weight: 600;
          width: 50%;
          height: 40px;
        }
      }
    }

    .news_progress_bar_wrapper {
      .news_progress_bar_background {
        height: 6px;
      }
      .news_progress_bar_indicator {
        height: 6px;
      }
    }
  }
}
