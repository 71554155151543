@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(1.25rem);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.service_content_wrapper {
  width: 100%;
  background-color: #242424;

  .service_content_inner {
    width: 1200.03px;
    margin: 0 auto;

    .service_container {
      height: 100%;
      background-color: #242424;
      text-align: left;
      padding: 18.812rem 0;
      font-weight: 700;

      .solution_title {
        color: #ffffff;
        font-size: 3.438em;
        margin-bottom: 1.674rem;
      }

      .solution_text {
        color: #ffffff;
        font-size: 1.813em;
        font-weight: 600;
        margin-bottom: 3.611rem;
      }

      .grid_container {
        display: flex;
      }

      .box_container {
        border-radius: 1.375rem;
        background-color: #242424;
        color: #ffffff;
        border: 0.125rem solid #383a3a;
        padding: 3.073rem 3.63rem;
        display: flex;
        cursor: pointer;
        align-items: flex-end;
        gap: 10.151rem;
        overflow: hidden;
        background: linear-gradient(to left, #242424 50%, #ffffff 50%);
        background-size: 74rem 100rem;
        background-position: right;
        transition: background-position 0.5s ease, color 0.5s ease;

        &:first-child {
          margin-right: 1.875rem;
        }
        &:hover,
        &.hovered {
          background-position: left;
          color: #222222;
          border-color: #383a3a;

          .more_arrow {
            fill: #222222;
          }

          .frame_component {
            fill: #222222;
            stroke: #222222;
          }

          .box_inner_context .learn_more_link {
            border-bottom: 2px solid #222222;
            padding-bottom: 0.2rem;
          }
        }

        .box_inner_context {
          .service_name {
            font-weight: 700;
            font-size: 1.875em;
            margin-bottom: 1rem;
          }

          .sub_name {
            color: #999999;
            font-size: 1em;
            margin-bottom: 6.131rem;
          }

          .learn_more_link {
            font-weight: 600;
            font-size: 1.25em;
            display: inline-flex;
            align-items: center;
            white-space: nowrap;
            border-bottom: 2px solid #ffffff;
            transition: border-bottom-color 0.5s ease;
            padding-bottom: 0.2rem;

            .more_arrow {
              width: 0.437rem;
              margin-left: 0.2rem;
              fill: #ffffff;
              transition: fill 0.5s ease;
            }
          }
        }

        .frame_box {
          flex-grow: 1;
          display: flex;
          height: 100%;
          justify-content: flex-end;
          align-items: flex-end;
          .frame_content {
            display: flex;
            align-items: flex-end;

            .frame_component {
              width: 13.164rem;
              height: 9.427rem;
              fill: #ffffff;
              stroke: #ffffff;
              transition: fill 0.4s ease, stroke 0.3s ease;

              &.hovered {
                fill: #222222;
                stroke: #222222;
              }
            }
          }
        }

        &.solution_box_container {
          width: 50%;
          .solution_frame_component {
            min-width: 13.164rem;
            min-height: 9.427rem;
            padding-right: 1rem;
          }
        }

        &.service_box_container {
          width: 50%;
          .service_frame_component {
            min-width: 8.831rem;
            min-height: 9.264rem;
          }
        }
      }
    }
  }
}

@include laptop {
  .service_content_wrapper {
    .service_content_inner {
      width: 1000px;

      .service_container {
        .solution_title {
          font-size: 3em;
        }

        .solution_text {
          font-size: 1.6em;
        }
        .box_container {
          padding: 2rem 2.5rem;
          gap: 5rem;

          &.solution_box_container {
            .solution_frame_component {
              min-width: 15rem;
              min-height: 11rem;
              padding-right: 0.5rem;
            }
          }

          &.service_box_container {
            .service_frame_component {
              min-width: 10rem;
              min-height: 10.5rem;
            }
          }
        }
      }
    }
  }
}

@include tablet {
  .service_content_wrapper {
    .service_content_inner {
      width: 100%;
      padding: 0 26px;
      .service_container {
        padding: 193.41px 0;

        .solution_title {
          font-size: 42px;
          font-weight: 700;
          text-align: center;
        }

        .solution_text {
          font-size: 27px;
          font-weight: 500;
          text-align: center;
          white-space: pre-wrap;
          line-height: 1.3;
        }

        .grid_container {
          flex-direction: column;
          gap: 35.5px;
        }

        .box_container {
          gap: 0;
          justify-content: space-between;
          background-size: 200% 100%;

          &:first-child {
            margin-right: 0;
          }

          .box_inner_context {
            .service_name {
              font-size: 42px;
              font-weight: 700;
              margin-bottom: 33.34px;
            }

            .sub_name {
              font-size: 20px;
              font-weight: 600;
            }

            .learn_more_link {
              font-size: 29px;
              font-weight: 500;
              letter-spacing: -1.74px;
              gap: 1px;

              .more_arrow {
                width: 0.6rem;
                margin-bottom: 0.5rem;
              }
            }
          }

          &.solution_box_container {
            width: 100%;
            .solution_frame_component {
              min-width: 274.2px;
              min-height: 196.3px;
              padding-right: 0rem;
            }
          }

          &.service_box_container {
            width: 100%;
            .service_frame_component {
              min-width: 183.934px;
              min-height: 193.176px;
            }
          }
        }
      }
    }
  }
}

@include tabletToMobile {
  .service_content_wrapper {
    .service_content_inner {
      width: 100%;
      padding: 0 26px;
      .service_container {
        padding: 193.41px 0;

        .solution_title {
          font-size: 42px;
          font-weight: 700;
          text-align: center;
        }

        .solution_text {
          font-size: 27px;
          font-weight: 500;
          text-align: center;
          white-space: pre-wrap;
          line-height: 1.3;
        }

        .grid_container {
          flex-direction: column;
          gap: 35.5px;
        }

        .box_container {
          gap: 0;
          justify-content: space-between;
          background-size: 200% 100%;

          &:first-child {
            margin-right: 0;
          }

          .box_inner_context {
            .service_name {
              font-size: 42px;
              font-weight: 700;
              margin-bottom: 33.34px;
            }

            .sub_name {
              font-size: 20px;
              font-weight: 600;
            }

            .learn_more_link {
              font-size: 29px;
              font-weight: 500;
              letter-spacing: -1.74px;
              gap: 1px;

              .more_arrow {
                width: 0.6rem;
                margin-bottom: 0.5rem;
              }
            }
          }

          &.solution_box_container {
            .solution_frame_component {
              min-width: 274.2px;
              min-height: 196.3px;
              padding-right: 0rem;
            }
          }

          &.service_box_container {
            .service_frame_component {
              min-width: 183.934px;
              min-height: 193.176px;
            }
          }
        }
      }
    }
  }
}

@include mobile {
  .service_content_wrapper {
    .service_content_inner {
      .service_container {
        padding: 120px 0;

        .solution_title {
          font-size: 38px;
        }

        .solution_text {
          font-size: 1.1em;
          line-height: 30px;
          margin-bottom: 3.5rem;
        }

        .box_container {
          padding: 2rem 1rem 2rem 2.5rem;

          .box_inner_context {
            .service_name {
              font-size: 1.5em;
              font-weight: 700;
              margin-bottom: 25px;
            }

            .sub_name {
              font-size: 0.9em;
              margin-bottom: 4em;
            }

            .learn_more_link {
              font-size: 1em;
              letter-spacing: -0.8px;
            }
          }

          &.solution_box_container {
            .solution_frame_component {
              min-width: 80%;
              min-height: 75%;
              max-width: 80%;
              max-height: 75%;
              margin-left: auto;
            }
          }

          &.service_box_container {
            .service_frame_component {
              min-width: 60%;
              min-height: 60%;
              max-width: 60%;
              max-height: 60%;
              margin-left: auto;
            }
          }
        }
      }
    }
  }
}
